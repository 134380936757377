import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Container, Flex, HStack, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { findMaxId } from "../../../../Constants";
import SaveButton from '../../../Save';
import { CustomSpinner } from "../../../Spinner";
import RegionalLeaderTable from "../RegionalLeaderTable";
// import useFetch from '../../../hooks/useFetchWithMsal';
import { ReactGrid } from '@silevis/reactgrid';
// import TextWrapRenderer from './TextWrapRenderer';
// import * as FileSaver from "file-saver";
import { Button, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { BsCardList } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineTableRows } from "react-icons/md";
import useFetch from '../../../../hooks/useFetchWithMsal';
import ChangeLog from "../../../ChangeLog/ChangeLog";
import CustomAlertDialogBox from '../../../CustomAlertDialogBox/CustomAlertDialogBox';
import { getCellClassName } from "../RegionalCanvasHelper";
import './../style.scss';

export default function DropDownTables({ setDataSaved, ActiveEnv, CustomerMasterRows, CustomerRowMapping, SupplySkuNonEditableRows, SupplySkuColumnsDatabase, DropDownRows, DropDownMasters, DropDownMasterColumnsMapping, CustomColumnWidth, dependenciesMapping, retrieveURL, updateURL, deleteURL }) {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { pathname } = useLocation()
    const [int1, setInt] = useState(0)
    const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"))
    const [id_sheet,] = useState(pathname ? parseInt(pathname.split('-')[1])  : 0)
    const navigate = useNavigate()
    const [dataGrouped, setDataGrouped] = useState({})
    const [dataGroupedOriginal, setDataGroupedOriginal] = useState({})
    const [DropDownMaster, setDropDownMaster] = useState({})
    const [maxID, setMaxID] = useState(dataGrouped ? findMaxId(Object.entries(dataGrouped),"splitId") : 0)
    const [maxIDInDB, setMaxIDInDB] = useState(dataGrouped ? findMaxId(Object.entries(dataGrouped),"splitId") : 0)
    const [{loading,error},fetchData] = useFetch()
    const [,updateData] = useFetch()
    const [,deleteData] = useFetch()
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [changesTracker, setChangesTracker] = useState({});
    const toast = useToast();
    const toastIdRef = useRef();
    const [deleteTracker,setDeleteTracker]=useState({})
    const [deleteCheckBox,setDeleteCheckBox]=useState(false)
    const [comment,setComment]=useState("")
    const [deleteConfirmation,setDeleteConfirmation]=useState(null)
    const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const getOptionType = useCallback((DropDownMaster) => {
        if (Object.keys(DropDownMaster).length === 0) return {}
        var masterMappingDict = {}
        DropDownMasters.forEach((masterMappings) => {
            masterMappingDict[masterMappings] = Object.entries(DropDownMaster[masterMappings]).map(([key, value]) => {
                return { label: `${key}`, value: `${key}` }
            })
        })

        return masterMappingDict
    }, [DropDownMasters])

    const [optionType, setOptionType] = useState({})

    const handleChanges = (changes) => {
        setInt(int1 + 1)
        if (changes[0].type === 'dropdown' && changes[0].newCell.text === changes[0].previousCell.text) {
            setRows((rowsTemp) => {
                let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId)
                dataRow["cells"][columns.findIndex((columnId) => columnId["columnId"] === changes[0]["columnId"])]["isOpen"] = changes[0].newCell.isOpen
                return [...rowsTemp]
            })
        } else if (changes[0].type === 'checkbox' && changes[0].newCell.checked !== changes[0].previousCell.checked) {
            setRows((rowsTemp) => {
                let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId)
                dataRow["cells"][columns.findIndex((columnId) => columnId["columnId"] === changes[0]["columnId"])]["checked"] = changes[0].newCell.checked
                return [...rowsTemp]
            })
            setDeleteTracker((prevData)=> {
                var updatedData=prevData
                var rowstodelete = updatedData["rowstodelete"]?updatedData["rowstodelete"]:[]
                if (changes[0].newCell.checked) {
                    if (rowstodelete.indexOf(changes[0].rowId)===-1) {
                        rowstodelete.push(changes[0].rowId)
                    }
                } else {
                    const indextoremove = rowstodelete.indexOf(changes[0].rowId);
                    if (indextoremove !== -1) {
                        rowstodelete.splice(indextoremove,1)
                    }
                }
                updatedData["rowstodelete"] =rowstodelete
                return updatedData
            })
        } else {
            setIsButtonDisabled(false);
            setDataSaved(false)
            if ((changes[0].newCell.text === "" || changes[0].newCell.text === null) && changes[0].columnId !== "Comment") {
                const toastSettings = {
                    description: changes[0].columnId + " cannot be left blank",
                    status: 'error',
                    isClosable: true,

                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
            }else if ((changes[0].newCell.value<0 || changes[0].newCell.value > 52) && changes[0].columnId === "WOS") {
                const toastSettings = {
                    description: changes[0].columnId + " should have value between 0 and 52",
                    status: 'error',
                    isClosable: true,
                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
            }else if ( (changes[0].columnId === "Till Week Ending")&&(changes[0].newCell.date===null||changes[0].newCell.date===''||changes[0].newCell.date.getDay()!==0||changes[0].newCell.date<(new Date())) ){
                var description = ""
                
                if (changes[0].newCell.date===null||changes[0].newCell.date==='') {
                        description = 'Selected Date should not be null'
                        
                } else if (changes[0].newCell.date.getDay()!==0 ) {
                        description = 'Selected Date should be a Week Ending'
                }
                        
                else {
                        description = 'Selected Date should be in future.'
                }
                const toastSettings = {
                    description: description,
                    status: 'error',
                    isClosable: true,
                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }

            } else {
                let dataTemp = dataGrouped
                changes.forEach((change) => {
                    const policy_id = change.rowId.split('-')[0]
                    const Id = change.rowId.split('-')[1]
                    if (parseInt(policy_id) > maxID) {
                        let dictionaryToSet = {
                            "SS_ID": 1,
                            "policy_id": parseInt(policy_id)

                        }
                        dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text;
                        var Poer = change.columnId
                        CustomerMasterRows.filter((value) => !(value === Poer || value === "ID" || value === "Policy ID")).forEach((value) => {
                            dictionaryToSet[CustomerRowMapping[value]] = ""
                        })
                        if (DropDownRows.findIndex((value) => value === change.columnId) !== -1) {
                            dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                                let mainDict = DropDownMaster[
                                    DropDownMasterColumnsMapping[
                                      CustomerRowMapping[change.columnId]
                                    ]
                                  ]
                                  dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                                    ? mainDict[change.newCell.text][keyofmapping]
                                    : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                                });
                              
                        }
                        setChangesTracker((prevChanges) => {
                            const updatedChanges = { ...prevChanges };
                            let dictionaryToSend = {}
                            let newCustomer = policy_id > maxIDInDB ? true : false;
                            const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                            let newTillDateEndingAdded = {}
                            let wos_policy = {}
                            if (change.columnId === 'Till Week Ending') {
                                newTillDateEndingAdded[`${1}-new`] = dictionaryToSet["till_week_ending_date"]
                            }
                            else if (change.columnId === 'WOS') {
                                wos_policy[`${1}-new`] = dictionaryToSet["min_ss_policy"]
                            }
                            else if (change.columnId === "Policy Name") {
                                dictionaryToSend["SS_Name"] = dictionaryToSet["SS_Name"]
                            }
                            updatedChanges[key] = dictionaryToSend
                            updatedChanges[key]["min_ss_policy"] = wos_policy
                            updatedChanges[key]["tillWeekEndingDateAdded"] = newTillDateEndingAdded
                            return updatedChanges;
                        });


                        dataTemp[`(${policy_id}, 1)`] = dictionaryToSet
                    }
                    else {
                        if (change.columnId === "Policy Name") {
                            Object.entries(dataTemp).filter(([entrykey, entryvalue]) => entryvalue["policy_id"] === parseInt(policy_id)).forEach(([entrykey, entryvalue]) => {
                                let dictionaryToSet = dataTemp[entrykey]
                                dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text
                                dataTemp[entrykey] = dictionaryToSet
                            })
                            setChangesTracker((prevChanges) => {
                                let newCustomer = policy_id > maxIDInDB ? true : false;
                                const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                const updatedChanges = { ...prevChanges };
                                let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                dictionaryToSend["SS_Name"] = change.newCell.text
                                dictionaryToSend["old_dictionary"]=dictionaryToSend["old_dictionary"]??{}
                                if(!newCustomer){
                                dictionaryToSend["old_dictionary"]["SS_Name"]=Object.entries(dataGroupedOriginal).filter(([entrykey, entryvalue]) => entryvalue["policy_id"] === parseInt(policy_id))[0][1]["SS_Name"]
                                }
                                updatedChanges[key] = dictionaryToSend
                                return updatedChanges;
                            });

                        } else if ((dataTemp[`(${policy_id}, ${Id})`])) {
                            if (change.columnId === "Till Week Ending") {
                                let dictionaryToSet = dataTemp[`(${policy_id}, ${Id})`]
                                dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text
                                if (DropDownRows.findIndex((value) => value === change.columnId) !== -1) {
                                    dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                                        let mainDict = DropDownMaster[
                                            DropDownMasterColumnsMapping[
                                              CustomerRowMapping[change.columnId]
                                            ]
                                          ]
                                          dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                                            ? mainDict[change.newCell.text][keyofmapping]
                                            : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                                        });
                                }
                                dataTemp[`(${policy_id}, ${Id})`] = dictionaryToSet
                                if (dataGroupedOriginal[`(${policy_id}, ${Id})`]) {
                                    setChangesTracker((prevChanges) => {       
                                        let newCustomer = policy_id > maxIDInDB ? true : false;                               
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        const updatedChanges = { ...prevChanges };
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let newWarehouseChanged = dictionaryToSend["TillWeekEndingChanged"]?dictionaryToSend["TillWeekEndingChanged"]:{}
                                        newWarehouseChanged[dataGroupedOriginal[`(${policy_id}, ${Id})`]["till_week_ending_date"]] = dictionaryToSet["till_week_ending_date"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["TillWeekEndingChanged"] = newWarehouseChanged
                                        return updatedChanges;
                                    });
                                } else {
                                    setChangesTracker((prevChanges) => {
                                        const updatedChanges = { ...prevChanges };
                                        let newCustomer = policy_id > maxIDInDB ? true : false;
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let tillWeekEndedDateAdded = dictionaryToSend["tillWeekEndingDateAdded"]?dictionaryToSend["tillWeekEndingDateAdded"]:{}
                                        tillWeekEndedDateAdded[`${Id}-new`] = dictionaryToSet["till_week_ending_date"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["tillWeekEndingDateAdded"] = tillWeekEndedDateAdded
                                        return updatedChanges;
                                    });
                                }
                            }
                            else if (change.columnId === "WOS") {
                                let dictionaryToSet = dataTemp[`(${policy_id}, ${Id})`]
                                dictionaryToSet[CustomerRowMapping[change.columnId]] = parseFloat(change.newCell.value)
                                dataTemp[`(${policy_id}, ${Id})`] = dictionaryToSet
                                if (dataGroupedOriginal[`(${policy_id}, ${Id})`]) {
                                    setChangesTracker((prevChanges) => {
                                        const updatedChanges = { ...prevChanges };
                                        let newCustomer = policy_id > maxIDInDB ? true : false;
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let ProportionsChanged = dictionaryToSend["min_ss_policy"]?dictionaryToSend["min_ss_policy"]:{}
                                        ProportionsChanged[dataGroupedOriginal[`(${policy_id}, ${Id})`]["till_week_ending_date"]] = dictionaryToSet["min_ss_policy"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["min_ss_policy"] = ProportionsChanged
                                        let old_dictionary=updatedChanges[key]['old_dictionary']??{}
                                        old_dictionary["min_ss_policy"]=old_dictionary["min_ss_policy"]??{}
                                        old_dictionary["min_ss_policy"][dataGroupedOriginal[`(${policy_id}, ${Id})`]["till_week_ending_date"]]=dataGroupedOriginal[`(${policy_id}, ${Id})`]["min_ss_policy"]
                                        updatedChanges[key]["old_dictionary"]=old_dictionary
                                        return updatedChanges;
                                    });
                                } else {
                                    setChangesTracker((prevChanges) => {
                                        const updatedChanges = { ...prevChanges };
                                        let newCustomer = policy_id > maxIDInDB ? true : false;
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let ProportionsAdded = dictionaryToSend["min_ss_policy"]?dictionaryToSend["min_ss_policy"]:{}
                                        ProportionsAdded[`${Id}-new`] = dictionaryToSet["min_ss_policy"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["min_ss_policy"] = ProportionsAdded
                                        return updatedChanges;
                                    });
                                }
                            }
                        } else {
                            if (Id === "new") {
                                if (change.columnId === "Till Week Ending") {
                                    let arrayofkeys = Object.entries(dataTemp).filter(([entrykey, entryvalue]) => {
                                        return entryvalue["policy_id"] === parseInt(policy_id)
                                    })
                                    let lastdictionary = arrayofkeys[arrayofkeys.length - 1][1]
                                    let dictionaryToSet = JSON.parse(JSON.stringify(lastdictionary))
                                    dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text
                                    dictionaryToSet["min_ss_policy"] = null
                                    if (DropDownRows.findIndex((value) => value === change.columnId) !== -1) {
                                        dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                                            let mainDict = DropDownMaster[
                                            DropDownMasterColumnsMapping[
                                              CustomerRowMapping[change.columnId]
                                            ]
                                          ]
                                          dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                                            ? mainDict[change.newCell.text][keyofmapping]
                                            : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                                        });
                                    }
                                    dictionaryToSet["SS_ID"] = lastdictionary["SS_ID"] + 1
                                    dataTemp[`(${policy_id}, ${lastdictionary["SS_ID"] + 1})`] = dictionaryToSet
                                    setChangesTracker((prevChanges) => {
                                        const updatedChanges = { ...prevChanges };
                                        let newCustomer = policy_id > maxIDInDB ? true : false;
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let TillWeekEndedDateAdded = dictionaryToSend["tillWeekEndingDateAdded"]?dictionaryToSend["tillWeekEndingDateAdded"]:{}
                                        TillWeekEndedDateAdded[`${lastdictionary["SS_ID"]+1}-new`] = dictionaryToSet["till_week_ending_date"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["tillWeekEndingDateAdded"] = TillWeekEndedDateAdded
                                        return updatedChanges;
                                    });

                                }
                                else if (change.columnId === "WOS") {
                                    let arrayofkeys = Object.entries(dataTemp).filter(([entrykey, entryvalue]) => entryvalue["policy_id"] === parseInt(policy_id))
                                    let lastdictionary = arrayofkeys[arrayofkeys.length - 1][1]
                                    let dictionaryToSet = JSON.parse(JSON.stringify(lastdictionary))
                                    dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.value
                                    dictionaryToSet["till_week_ending_date"] = null
                                    // dictionaryToSet["warehouse_name"] = null
                                    dictionaryToSet["SS_ID"] = lastdictionary["SS_ID"] + 1
                                    dataTemp[`(${policy_id}, ${lastdictionary["SS_ID"] + 1})`] = dictionaryToSet
                                    setChangesTracker((prevChanges) => {
                                        const updatedChanges = { ...prevChanges };
                                        let newCustomer = policy_id > maxIDInDB ? true : false;
                                        const key = `${ActiveEnv},${policy_id},${newCustomer}`;
                                        let dictionaryToSend = updatedChanges[key] ? updatedChanges[key] : {}
                                        let min_ss_policy_added = dictionaryToSend["min_ss_policy"]?dictionaryToSend["min_ss_policy"]:{}
                                        min_ss_policy_added[`${lastdictionary["SS_ID"] + 1}-new`] = dictionaryToSet["min_ss_policy"]
                                        updatedChanges[key] = dictionaryToSend
                                        updatedChanges[key]["min_ss_policy"] = min_ss_policy_added
                                        return updatedChanges;
                                    });
                                }
                            }
                        }

                    }
                })
                setDataGrouped(dataTemp)
                let arrayofkeys = Object.entries(dataTemp)
                setMaxID(findMaxId(arrayofkeys,"policy_id"))
                setRows(getRows(dataTemp,dataGroupedOriginal,optionType,deleteCheckBox))
            }
        }
    }

    function findLongestStringBySplitId(data) {
        const longestStringsBySplitId = {};
      
        for (const key in data) {
          const { splitId, warehouse_name } = data[key];
          longestStringsBySplitId[splitId] = longestStringsBySplitId[splitId] || "";
          longestStringsBySplitId[splitId] = longestStringsBySplitId[splitId] +","+ warehouse_name;
        }
      
        let longestString = "";
        let longestSplitId;
      
        for (const splitId in longestStringsBySplitId) {
          const currentString = longestStringsBySplitId[splitId];
          if (currentString.length > longestString.length) {
            longestString = currentString;
            longestSplitId = splitId;
          }
        }
      
        return {
          longestString,
          splitId: longestSplitId ? parseInt(longestSplitId.split(",")[0]) : undefined, // Extract the splitId number
        };
      }

    const getColumns = useCallback((data,deleteCheckBox1) => {
        if (Object.keys(data).length === 0) return [];
        var CustomerMasterRows1 = CustomerMasterRows
        if (deleteCheckBox1) {
            CustomerMasterRows1=['Delete',...CustomerMasterRows]
        }
        return [...CustomerMasterRows1.map((column) => {
            return { columnId: column, width: Math.max(...[column.length, ...Object.values(data).map(d => `${d[CustomerRowMapping[column]]}`.length)]) * (
                DropDownRows.indexOf(column) > -1 ? 15 : 10
            ), resizable: true };
        })];
    }, [CustomerMasterRows,CustomerRowMapping,DropDownRows])



    const getRows = useCallback((pivotedData, dataGroupedOriginal,optionType, deleteCheckBox1) => {
        if (Object.keys(pivotedData).length === 0 ||  (Object.keys(optionType).length === 0 && DropDownRows.length !== 0)) return [];
        var CustomerMasterRows1=CustomerMasterRows
        if (deleteCheckBox1) {
            CustomerMasterRows1=['Delete',...CustomerMasterRows]
        }
        const headerRowCells = CustomerMasterRows1.map((column) => {
            return { type: "header", text: column, className: "header-cell " }
        })
        let rows = [
            {
                rowId: "header",
                cells: [...headerRowCells]
            },
        ]

        let dictionaryRow = [...rows]
        var policy_id = 1
        let lastdiscontinuated = ''
        var counter = 1
        Object.entries(pivotedData).sort(([par1, val1], [par2, val2]) => { 
            let af = parseFloat(val1["policy_id"]);
            let bf = parseFloat(val2["policy_id"]);
            let as = (new Date(val1["till_week_ending_date"])).getTime();
            let bs = (new Date(val2["till_week_ending_date"])).getTime();
            if (af < bf) {
                return -1;
            } else if (af > bf) {
                return 1;
            } else {
                // Dates are equal, compare priorities
                return as - bs;
            }
         }).forEach(([parentRowName, value1]) => {
            if (value1["policy_id"] !== policy_id) {
                let oneSingleRow = {
                    rowId: `${policy_id}-new`,
                    cells: [...CustomerMasterRows1.map((value) => {
                        if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
                            return { type: "dropdown", isOpen: false, values: optionType[DropDownMasterColumnsMapping[CustomerRowMapping[value]]], className: "editable-cell" + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ) }
                        } else if (value==='Delete') {
                            return { type: "text", className: "editable-cell" + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ) , text:""}
                        }
                        else if (value === "WOS") {
                            return { type: "number", value: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                        }
                        else if (value === "Policy ID") {
                            return { type: "text", text: `${policy_id}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                        }
                        else if (value === "Till Week Ending") {
                            return { type: "date", date: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                        }
                        else if (value === "Policy Name") {
                            return { type: "text", text: `${lastdiscontinuated}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                        }
                        else {
                            return { type: "text", text: '', className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                        }
                    }
                    )]
                }
                dictionaryRow.push(oneSingleRow)
                counter = counter + 1
            }
            let twoSingleRow = {
                rowId: `${value1["policy_id"]}-${value1["SS_ID"]}`,
                cells: [...CustomerMasterRows1.map((value) => {
                    if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
                        
                            let dropdownOption = optionType[
                              DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                            ]
                            return {
                              type: "dropdown",
                              selectedValue: `${value1[CustomerRowMapping[value]]}`,
                              isOpen: false,
                              values:dataGroupedOriginal[parentRowName]?
                              dropdownOption.findIndex((value4)=>value4['label']===dataGroupedOriginal[parentRowName][CustomerRowMapping[value]])!==-1?dropdownOption:[{label:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`,value:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`},...dropdownOption]:dropdownOption,
                              className: "editable-cell" +
                              (counter % 2 === 0 ? "-dark " : "-light "),
                            };
                    } else if (value==='Delete') {
                        return { type: "checkbox", className: 'pale-red-bg ' , checked: false}
                    }
                    else if (value === "WOS") {
                        return { type: "number", value: value1[CustomerRowMapping[value]], className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark" : "-light"
                            ) + (
                                (value1[CustomerRowMapping[value]] === null||value1[CustomerRowMapping[value]] === '') ? " pale-red-bg " : " "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                    }
                    else if (value === "Policy ID") {
                        return { type: "text", text: `${value1["policy_id"]}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                    }
                    else if (value === "Till Week Ending") {
                        return { type: "date", date: new Date(value1[CustomerRowMapping[value]]), className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                    }
                    else {
                        return { type: "text", text: `${value1[CustomerRowMapping[value]]}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                    }
                }
                )]
            }
            dictionaryRow.push(twoSingleRow)
            lastdiscontinuated = value1["SS_Name"]
            policy_id = value1["policy_id"]
            
        })
        
        let oneSingleRow = {
            rowId: `${policy_id}-new`,
            cells: [...CustomerMasterRows1.map((value) => {
                if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
                    return { type: "dropdown", isOpen: false, values: optionType[DropDownMasterColumnsMapping[CustomerRowMapping[value]]], className: "editable-cell" + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ) }
                } else if (value==='Delete') {
                    return { type: "text", className: "editable-cell" + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ) , text:""}
                }
                else if (value === "WOS") {
                    return { type: "number", value: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else if (value === "Policy ID") {
                    return { type: "text", text: `${policy_id}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else if (value === "Till Week Ending") {
                    return { type: "date", date: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else if (value === "Policy Name") {
                    return { type: "text", text: `${lastdiscontinuated}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else {
                    return { type: "text", text: '', className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                ((counter % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
            }
            )]
        }
        dictionaryRow.push(oneSingleRow)
        counter = counter + 1
        dictionaryRow.push({
            rowId: `${policy_id+1}-new`,
            cells: [...CustomerMasterRows1.map((value) => {
                if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
                    return { type: "dropdown", isOpen: false, values: optionType[DropDownMasterColumnsMapping[CustomerRowMapping[value]]], className: "editable-cell" + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ) }
                } else if (value==='Delete') {
                    return { type: "text", className: "editable-cell" + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ) , text:""}
                }
                else if (value === "WOS") {
                    return { type: "number", value: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else if (value === "Policy ID") {
                    return { type: "text", text: `${policy_id+1}`, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else if (value === "Till Week Ending") {
                    return { type: "date", date: null, className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                else {
                    return { type: "text", text: "", className: getCellClassName(SupplySkuNonEditableRows, value) + (
                                (((counter) % 2) === 0) ? "-dark " : "-light "
                            ), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
            }
            )]
        })
        return dictionaryRow;
    }, [CustomerRowMapping, DropDownMasterColumnsMapping, DropDownRows, SupplySkuNonEditableRows, CustomerMasterRows])

    const handleColumnResize = (ci, width) => {
        if (Object.keys(dataGrouped).length === 0) return [];
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])


console.log(changesTracker)
    


    useEffect(() => {
        if (!account) {
            navigate("/")
        } else {
            if (ActiveEnv && Object.keys(dataGrouped).length === 0) {
                fetchData( retrieveURL, { method: "GET" }).then((data) => {
                    setDataGrouped(data["ExistingTable"])
                    setDataGroupedOriginal(JSON.parse(JSON.stringify(data["ExistingTable"])))
                    setDropDownMaster(data["DropdownMasters"] ? data["DropdownMasters"] : {});
                    var temp =getOptionType(data["DropdownMasters"] ? data["DropdownMasters"] : {});
                    setOptionType(temp)
                    setRows(getRows(data["ExistingTable"],data["ExistingTable"] ,temp,deleteCheckBox))
                    setColumns(getColumns(data["ExistingTable"],deleteCheckBox))
                    const maxIDinEffect = findMaxId(Object.entries(data["ExistingTable"]),"policy_id")
                    setMaxID(maxIDinEffect)
                    setMaxIDInDB(maxIDinEffect)
                })
                setDataSaved(true)

            }
        }
    }, [account, navigate, ActiveEnv, dataGrouped, setDataSaved, retrieveURL, getRows, getColumns, getOptionType,deleteCheckBox,fetchData])

    const uploadData = () => {
        setIsButtonDisabled(true);
        let data = [];
        let till_week_ending_date = []
        let SS_Name = []
        Object.entries(changesTracker).forEach(([key1, value1]) => {
            const policy_id = key1.split(',')[1]
                Object.entries(dataGrouped).filter(([key3, value3]) => value3["policy_id"] === parseInt(policy_id)).forEach(([key4, value4]) => {
                    if (value4['SS_Name'] ==="") {
                        SS_Name.push(policy_id)
                    }
                    if (value4['till_week_ending_date'] ==="") {
                        till_week_ending_date.push(policy_id)
                    }
                })
            })
        if ( till_week_ending_date.length>0 || SS_Name.length>0) {
            let ErrorMsg = ""
        if (till_week_ending_date.length > 0) {
            till_week_ending_date.forEach((value) => {
                ErrorMsg = ErrorMsg + "Till Week Ending Date is blank for ID "+ value+"."
            })
        }

        if (SS_Name.length > 0) {
            SS_Name.forEach((value) => {
                ErrorMsg = ErrorMsg + "WOS is blank for ID "+ value+"."
            })
        }
            const toastSettings = {
                description: ErrorMsg,
                status: 'error',
                isClosable: true,

            }
            if (toastIdRef.current) {
                toast.update(toastIdRef.current, toastSettings)
            } else {
                toast(toastSettings)
            }
        } else {
            Object.entries(changesTracker).forEach(([key, newValue]) => {
                const [Env, ID, newCustomer] = key.split(",");
                data.push({
                    updatedDict: newValue,
                    policy_id: ID,
                    newCustomer: newCustomer,
                    comment:comment
                });
            });
            updateData(updateURL,{method:"PUT",body:data}).then((data) => {
                if (data.Error) return;
            }).then(() => {
                // updateTotals(totals, pivotedData, setTotals, setPivotedData, changesTracker)
                setDataSaved(true)
                setMaxIDInDB(findMaxId(Object.entries(dataGrouped),"policy_id"))
                setDataGroupedOriginal(JSON.parse(JSON.stringify(dataGrouped)))
                setChangesTracker({})
                setIsAlertBoxOpen(!isAlertBoxOpen);
                setDeleteConfirmation(null);
            }).catch((error) => {
                console.log(error)
            }
            );
        }
    };

    const deleteSelectedRows = async () => {
        const rowstodelete = deleteTracker['rowstodelete']
        let changesTracker1 = {...changesTracker}
        const blankSpace = []
        let dataGrouped1 = dataGrouped
        let dataGroupedOriginal1 = dataGroupedOriginal
            const rowstodatabase = rowstodelete.filter((value)=> {
            const [s,k] = value.split("-")
            const newCustomer = parseInt(s) > maxIDInDB ? true : false
 
            const key23 = `(${s}, ${k})`
           
            let changesTrackerModified = Object.entries(changesTracker1[`${ActiveEnv},${s},${newCustomer}`]).map(([key2,value2])=>{
                console.log(value2)
                if(key2 === "tillWeekEndingDateAdded"){
                    Object.keys(value2).forEach((ks)=>{
                        if (ks.includes(`${k}-new`)){
                            delete value2[ks]
                        }
                    })
                }
                if(key2 === "TillWeekEndingChanged"){
                    Object.keys(value2).forEach((ks)=>{
                        if (ks.includes(dataGroupedOriginal[key23]["till_week_ending_date"])){
                            delete value2[ks]
                        }
                    })
                }
                if(key2 === "min_ss_policy"){
                    Object.keys(value2).forEach((ks)=>{
                        if (dataGroupedOriginal[key23] && ks.includes(dataGroupedOriginal[key23]["till_week_ending_date"])){
                            delete value2[ks]
                        }
                        if (ks.includes(`${k}-new`)){
                            delete value2[ks]
                        }
                        
                    })
                }
                return [key2,value2]
            })
            let co = Object.fromEntries(changesTrackerModified)
            changesTracker1[`${ActiveEnv},${s},${newCustomer}`] = co
            return dataGroupedOriginal[key23]?true:false}).map((v36)=> {
                const [s,k] = v36.split("-")
                const key23 = `(${s}, ${k})`
                return {"policy_id":dataGroupedOriginal[key23]["policy_id"], "till_week_ending_date":dataGroupedOriginal[key23]["till_week_ending_date"]

                }
            })
            
        if (rowstodatabase.length>0) {
            let data={}
            data["rowstodeleteindatabase"] =rowstodatabase
            data["deleterow"] = true
            data["comment"] = comment
            data['PageName'] = pathname.split('-')[0]
            await deleteData(deleteURL , {method:"DELETE",body:data}).then((data) => {
                if (data.Error) return;
            }).then(() => {
                rowstodelete.forEach((value147)=> {
                    
                    const [s,k] = value147.split("-")
                    const key23 = `(${s}, ${k})`
                    delete dataGrouped1[key23]
                    if (dataGroupedOriginal1[key23]) {
                        delete dataGroupedOriginal1[key23]
                    }
                 })
                 if (Object.values(rowstodatabase).map((v36)=> {return v36["policy_id"]}).indexOf(maxIDInDB)!==-1) {
                        setMaxIDInDB(findMaxId(Object.entries(dataGrouped1),"policy_id"))
                        setMaxID(findMaxId(Object.entries(dataGrouped1),"policy_id"))
                 }
                setDataGrouped(dataGrouped1)
                setDataGroupedOriginal(JSON.parse(JSON.stringify(dataGroupedOriginal1)))
            }).catch((error) => {
                const toastSettings = {
                    description: error.response.data.Error,
                    status: 'error',
                    isClosable: true,
                    duration: 2000
                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
            }
            ) 
        } else {
                rowstodelete.forEach((value147)=> {
                    const [s,k] = value147.split("-")
                    const key23 = `(${s}, ${k})`
                    delete dataGrouped1[key23]
                 })
                 setDataGrouped(dataGrouped1)
                 setMaxID(findMaxId(Object.entries(dataGrouped1),"policy_id"))
                 const toastSettings = {
                    description: "Data Deleted Successfully",
                    status: 'success',
                    isClosable: true,
                    duration: 2000
                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
        }
            
            setDeleteTracker((prevData)=> {
                prevData['rowstodelete'] =[]
                return prevData
            })
        setColumns(getColumns(dataGrouped1,!deleteCheckBox))
        setRows(getRows(dataGrouped1,dataGroupedOriginal,optionType,!deleteCheckBox))
        setDeleteCheckBox(!deleteCheckBox)   
        setIsAlertBoxOpen(!isAlertBoxOpen);
        setChangesTracker(changesTracker1)
        setDeleteConfirmation(null);
    }
    
    const handleDeleteCheckbox = ()=> {
        if(!deleteCheckBox===false){
            setDeleteTracker((prevData)=>{
                prevData["rowstodelete"]=[]
                return prevData
            })
        }
        setDeleteCheckBox(!deleteCheckBox)
        setColumns(getColumns(dataGrouped,!deleteCheckBox))
        setRows(getRows(dataGrouped,dataGroupedOriginal,optionType,!deleteCheckBox))
    }
    if (error) return (<p>Follwing Error has occurred {error}</p>)
    if (loading || !ActiveEnv) return (
        <Container minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            < Box
                className="Loading-Data"
                maxW="md"
                mx="auto"
                p={6}
                borderRadius="xl"
                boxShadow="md"
                backgroundColor={"white"}
            >
                <CustomSpinner text="Loading Your Data.. Please Wait" />
            </Box >
        </Container>
    )

       return (
        <Flex flex="1" flexDirection="column">
            <Container maxW="container.xxl" py={8}>
                <Stack spacing={8}>
                    <Tabs variant="enclosed" bg="white" boxShadow="md">
                        <TabList>
                            <Tab>{prettySheets.filter((value)=>value[0]===parseInt(id_sheet))[0][1]}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <HStack spacing={4}>
                                    <SaveButton isButtonDisabled={isButtonDisabled} uploadData={() =>{setIsAlertBoxOpen(!isAlertBoxOpen);setDeleteConfirmation("Save")}} />
                                    
                                    <Button style={{ "marginTop": "16px", width: "130px" }} fontSize="md" fontWeight="bold" onClick={onOpen} colorScheme="blue" leftIcon={<BsCardList />}>Change Logs</Button>
                                    
                                    {!deleteCheckBox && <Button style={{"marginTop":"16px", width:"130px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckbox} colorScheme="red" leftIcon={<MdOutlineTableRows />}>Delete Rows</Button>}
                                    
                                    {deleteCheckBox && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={() =>{ setIsAlertBoxOpen(!isAlertBoxOpen);setDeleteConfirmation("Row")}} colorScheme="red" leftIcon={<MdOutlineTableRows />}>Delete Selected Rows</Button>}
                                    
                                    {deleteCheckBox && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckbox} colorScheme="yellow" leftIcon={<MdOutlineCancel />}>Cancel Delete Rows</Button>}

                                    {prettySheets && <Box position="absolute" right="2%"><RegionalLeaderTable name={prettySheets.filter((value)=>value[0]===parseInt(id_sheet))[0][1]} /></Box>}
                                </HStack>
                                <Box minHeight="10vh" paddingBottom={10}>
                                    <Box maxHeight="60vh" overflow="auto" textAlign="center" id='printableArea' className='react-grid'>
                                        {/* <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
                                        <div id="reactgrid-red">
                                            <ReactGrid rows={rows} columns={columns} stickyTopRows={1} stickyLeftColumns={deleteCheckBox?1:0} disableVirtualScrolling={false} onColumnResized={handleColumnResize} onCellsChanged={handleChanges} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid>
                                        </div>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Stack>
                {
                    (isAlertBoxOpen) && (
                        <CustomAlertDialogBox
                            isAlertBoxOpen={isAlertBoxOpen}
                            setIsAlertBoxOpen={setIsAlertBoxOpen}
                            themeColor={deleteConfirmation==="Save"?'green':'red'}
                            headerMsg={deleteConfirmation==="Save"?'Please Confirm to Save in Database':'Delete Selected Rows?'}
                            bodyMsg={"Are you sure? You can't undo this action afterwards."}
                            onOKHandler={deleteConfirmation==="Save"?uploadData:deleteSelectedRows}
                            onChangeCommentHandler={setComment}
                        />
                    )
                }
                {
                
        
                isOpen &&       <ChangeLog PageName={pathname.split("-")[0]} isOpen={isOpen} onClose={onClose} onOpen={onOpen}/>
            }
            </Container>
        </Flex>
    );
}

