// export const rowMapping = ["Stat Fcst - Total", "Stat Fcst - Focus Dealer" , "Stat Fcst - Non Focus Dealer", "Prev Fcst - Total",
//     "Prev Fcst - Focus Dealer" , "Prev Fcst - Non Focus Dealer", "Adjusted Regional Revenue Forecast", "Current Fcst - Focus Dealer", "Calc Current Fcst - Non Focus Dealer" , "Focus Dealer Adjusted vs Last Cycle", "Current Fcst - Total"];

export const getCellClassName = (nonEditableRowsList, rowName) => {
  return nonEditableRowsList.findIndex((row) => row === rowName) !== -1
    ? "non-editable-cell"
    : "editable-cell";
};

export const FormatDateToMonthYear = (dateStr) => {
  const date = new Date(dateStr);
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${month}-${year}`;
};

export const FormatDateToMonthYear1 = (date) => {
  if (!(date instanceof Date)) {
    return "Invalid Date";
  }

  // Get the month, day, and year
  var month = date.getMonth() + 1; // Months are zero-based
  var day = date.getDate();
  var year = date.getFullYear();

  // Pad single-digit month and day with leading zeros
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  // Create the formatted date string
  var formattedDate = month + "/" + day + "/" + year;

  return formattedDate;
};

// Customer Master
export const CustomerMasterRows = [
  "Ordering Customer Name",
  "Customer Name",
  "Customer Channel",
  "Customer Country",
];
export const CustomerRowMapping = {
  ID: "ID",
  "Ordering Customer Name": "ordering_customer_name",
  "Customer Name": "customer_name",
  "Customer Channel": "channel",
  "Customer Country": "customer_country",
};
export const CustomerMasterNonEditableRows = ["ID"];
export const CustomerMasterSKUColumnsDatabase = [
  "customer_name",
  "channel",
  "customer_country",
  "ID",
  "ordering_customer_name",
];
export const CustomerMasterDropDownRows = [];
export const CustomerMasterDropDownMasters = [];
export const CustomerMasterDropDownMasterColumnsMapping = {};
export const CustomerMasterDependenciesMapping = {};
export const CustomerMasterDropDownMasterIntRows = [];

// Warehouse Master
export const WarehouseMasterRows = [
  "Warehouse Name",
  "State/Province",
  "Warehouse Country",
  "Status",
  "Warehouse Type",
  "Production Time",
  "Ex-Factory Time",
  "ETD to Port ETA Leadtime",
  "Port ETA to Whse Door",
];
export const WarehouseRowMapping = {
  "ID": "ID",
  "Warehouse Name": "warehouse_name",
  "Warehouse Type": "warehouse_type",
  "State/Province": "state_province",
  "Warehouse Country": "warehouse_country",
  "Production Time": "production_time",
  "Ex-Factory Time": "Ex_Factory_Time",
  "Status": "Active",
  "ETD to Port ETA Leadtime": "Etd_Port_eta_Leadtime",
  "Port ETA to Whse Door": "eta_port_whse_time",
};
export const WarehouseNonEditableRows = ["ID"];
export const WarehouseEditableRows = [];
export const WarehouseColumnsDatabase = [
  "ID",
  "warehouse_name",
  "Status",
  "warehouse_type",
  "state_province",
  "warehouse_country",
  "production_time",
  "Ex_Factory_Time",
  "Active",
  "Etd_Port_eta_Leadtime",
  "eta_port_whse_time",
];
export const WarehouseDropDownRows = ["Status", "Warehouse Type"];
export const WarehouseDropDownMasters = [
  "StatusMapping",
  "WarehouseTypeMapping",
];
export const WarehouseDropDownMasterColumnsMapping = {
  Active: "StatusMapping",
  warehouse_type: "WarehouseTypeMapping",
};
export const WarehouseDependenciesMapping = {
  "Warehouse Type": [],
  Status: [],
};
export const WarehouseIntRows = [
  "Production Time",
  "Ex-Factory Time",
  "ETD to Port ETA Leadtime",
  "Port ETA to Whse Door",
  "Total Lead Time(Days)",
  "Total Lead Time(Weeks)",
];
export const WarehouseCalculatedColumns = [
  "Total Lead Time(Days)",
  "Total Lead Time(Weeks)",
];
export const WarehouseCalculatedColumnsMapping = {
  "Total Lead Time(Days)": [
    "Production Time",
    "Ex-Factory Time",
    "ETD to Port ETA Leadtime",
    "Port ETA to Whse Door",
  ],
  "Total Lead Time(Weeks)": [
    "Production Time",
    "Ex-Factory Time",
    "ETD to Port ETA Leadtime",
    "Port ETA to Whse Door",
  ],
};
export const WarehouseCalculatedColumnsOperations = {
  "Total Lead Time(Days)": ["sum"],
  "Total Lead Time(Weeks)": ["sum", "divide=7"],
};

// Safety Stock Assignment
export const SafetyStockAssignmentRows = [
  "ID",
  "Warehouse Name",
  "Supply SKU",
  "Policy Name",
];
export const SafetyStockAssignmentRowMapping = {
  "ID": "id",
  "Warehouse Name": "warehouse_name",
  "Supply SKU": "supply_sku",
  "Policy Name": "policy_name"
};
export const SafetyStockAssignmentNonEditableRows = ["ID"];
export const SafetyStockAssignmentColumnsDatabase = [
  "id",
  "warehouse_code",
  "supply_sku",
  "safety_stock_policy_id"
];
export const SafetyStockAssignmentDropDownRows = [
  "Warehouse Name",
  "Supply SKU",
  "Policy Name"
];
export const SafetyStockAssignmentDropDownMasters = [
  "WarehouseMasterMapping",
  "SKUMasterMapping",
  "SafetyStockPolicyMapping"
];
export const SafetyStockAssignmentDropDownMasterColumnsMapping = {
  "warehouse_name": "WarehouseMasterMapping",
  "supply_sku": "SKUMasterMapping",
  "policy_name": "SafetyStockPolicyMapping"
};
export const SafetyStockAssignmentDependenciesMapping = {
  "Warehouse Name": ["warehouse_code"],
  "Supply SKU": [],
  "Policy Name": ["safety_stock_policy_id"]
};
export const SafetyStockAssignmentDropDownMasterIntRows = [];
export const SafetyStockAssignmentCalculatedColumns = [];
export const SafetyStockAssignmentCalculatedColumnsMapping = {};

// S&OP Category
export const SOPCategoryMasterRows = ["SOP Category", "Category", "Sort Rank"];
export const SOPCategoryRowMapping = {
  "ID": "ID",
  "SOP Category": "SOPCategoryName",
  "Category": "CategoryName",
  "Sort Rank": "sort_rank",
};
export const SOPCategoryNonEditableRows = ["ID"];
export const SOPCategoryEditableRows = [];
export const SOPCategoryColumnsDatabase = [
  "ID",
  "SOPCategoryName",
  "CategoryName",
  "sort_rank",
];
export const SOPCategoryDropDownRows = [];
export const SOPCategoryDropDownMasters = [];
export const SOPCategoryDropDownMasterColumnsMapping = {};
export const SOPCategoryDependenciesMapping = {};
export const SOPCategoryIntRows = [];

// Demand SKU
export const DemandSKUTableRows = ["Demand SKU"];
export const DemandSKUTableRowsMapping = {
  ID: "id",
  "Demand SKU": "demand_sku",
};
export const DemandSKUNonEditableRows = ["ID"];
export const DemandSKUColumnsDatabase = ["id", "demand_sku"];
export const DemandSKUDropDownRows = [];
export const DemandSKUDropDownMasters = [];
export const DemandSKUDropDownMasterColumnsMapping = {};
export const DemandSKUDependenciesMapping = {};
export const DemandSKUIntRows = [];

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const SupplySkuMasterRows = [
  "Supply Sku",
  "S&OP Category",
  "Equalized Bags",
  "Category",
  "Bags Per Carton",
  "SKU Description",
  "Comment",
];
export const SupplySkuMasterMapping = {
  ID: "id",
  "Supply Sku": "supply_sku",
  "S&OP Category": "SOPCategoryName",
  Comment: "Comment",
  "Equalized Bags": "Equalized_Bags",
  "Bags Per Carton": "Bags_Per_Container",
  "SKU Description": "SKU_Description",
  Category: "CategoryName",
};

export const SupplySkuNonEditableRows = ["ID", "Category", "Date Added"];
export const SupplySkuColumnsDatabase = [
  "id",
  "supply_sku",
  "SOPCategoryName",
  "Comment",
  "Equalized_Bags",
  "Bags_Per_Container",
  "SKU_Description",
];
export const SupplySkudependenciesMapping = {
  "S&OP Category": ["CategoryName"],
};
export const SupplySkuDropDownRows = ["S&OP Category"];
export const SupplySkuDropDownMasters = ["SOPCategoryMapping"];
export const SupplySkuDropDownMasterColumnsMapping = {
  SOPCategoryName: "SOPCategoryMapping",
};
export const SupplySKUIntRows = [];

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const WarehouseSKUMasterRows = [
  "Warehouse Sku",
  "Supply Sku",
  "Conversion Multiplier",
  "S&OP Category",
  "Comment",
];
export const WarehouseSKUMasterMapping = {
  ID: "id",
  "Warehouse Sku": "warehouse_sku",
  "Supply Sku": "supply_sku",
  "Conversion Multiplier": "conversion_multiplier",
  "S&OP Category": "SOPCategoryName",
  Comment: "comment",
};

export const WarehouseSKUNonEditableRows = ["ID", "S&OP Category"];
export const WarehouseSKUColumnsDatabase = [
  "id",
  "supply_sku",
  "warehouse_sku",
  "conversion_multiplier",
  "comment",
];
export const WarehouseSKUdependenciesMapping = {
  "Supply Sku": ["SOPCategoryName"],
};
export const WarehouseSKUBlankRows = ["comment"];
export const WarehouseSKUMappingIntRows = ["Conversion Multiplier"];
export const WarehouseSKUDropDownRows = ["Supply Sku"];
export const WarehouseSKUDropDownMasters = ["SupplySkuMapping"];
export const WarehouseSKUDropDownMasterColumnsMapping = {
  supply_sku: "SupplySkuMapping",
};

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const ItemMasterNonEditableRows = [
  "ID",
  "Category",
  "Date Added",
  "Warehouse Country",
  "Channel",
  "Customer Country",
  "SOP Category",
  "Equalized Bags",
  "Category Name",
  "SKU_Description",
];
export const ItemMasterColumnsDatabase = [
  "id",
  "supply_sku",
  "warehouse_code",
  "customer_code"
];
export const ItemMasterRows = [
  "Warehouse Name",
  "Supply SKU",
  "Customer Name",
  "Warehouse Country",
  "Channel",
  "Customer Country",
  "SOP Category",
  "Equalized Bags",
  "Category Name",
  "SKU_Description",
];
export const ItemMasterRowMapping = {
  "ID": "id",
  "Warehouse Name": "warehouse_name",
  "Customer Name": "customer_name",
  "Supply SKU": "supply_sku",
  "Warehouse Country": "warehouse_country",
  "Channel": "channel",
  "Customer Country": "customer_country",
  "SOP Category": "SOPCategoryName",
  "Equalized Bags": "Equalized_Bags",
  "Category Name": "CategoryName",
  "SKU_Description": "SKU_Description"
};
export const ItemMasterGroupPolicy = {};
export const dependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country"],
  "Customer Name": ["customer_code", "channel", "customer_country"],
  "Supply SKU": [
    "Equalized_Bags",
    "SOPCategoryName",
    "SKU_Description",
    "CategoryName",
  ]
};
export const Dropdownrows = [
  "Warehouse Name",
  "Customer Name",
  "Supply SKU"
];
export const DropDownMasters = [
  "WarehouseMasterMapping",
  "CustomerMasterMapping",
  "SKUMasterMapping"
];
export const DropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
  customer_name: "CustomerMasterMapping",
  supply_sku: "SKUMasterMapping"
};
export const ItemMasterFilter = ["Channel", "Customer Name", "SOP Category"];
export const ItemMasterSortByFields = [];
export const ItemMasterIntRows = [];

export const GetUniqueValues = (dataGrouped, key, ItemMasterRowMapping) => {
  return [
    ...new Set(
      Object.entries(dataGrouped).map(
        ([key1, value]) => value[ItemMasterRowMapping[key]]
      )
    ),
  ];
};

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Conversion Master
export const NewConversionNonEditableRows = [
  "ID",
  "Warehouse Country",
  "Channel",
  "Customer Country",
  "S&OP Category",
];
export const NewConversionColumnsDatabase = [
  "id",
  "supply_sku",
  "warehouse_code",
  "customer_code",
  "demand_sku",
  "conversion_proportion",
  "volume_multiplier",
];
export const NewConversionRows = [
  "Demand SKU",
  "Customer Name",
  "Supply SKU",
  "Warehouse Name",
  "Conversion Proportion",
  "Volume Multiplier",
  "Channel",
  "Customer Country",
  "Warehouse Country",
  "S&OP Category",
];
export const NewConversionRowMapping = {
  ID: "id",
  "Warehouse Name": "warehouse_name",
  "Warehouse Country": "warehouse_country",
  "Customer Name": "customer_name",
  "Customer Country": "customer_country",
  "Supply SKU": "supply_sku",
  Channel: "channel",
  "S&OP Category": "SOPCategoryName",
  "Demand SKU": "demand_sku",
  "Conversion Proportion": "conversion_proportion",
  "Volume Multiplier": "volume_multiplier",
};
export const NewConversionDependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country"],
  "Customer Name": ["customer_code", "channel", "customer_country"],
  "Supply SKU": ["SOPCategoryName"],
  "Demand SKU": [],
};
export const NewConversionDropDownRows = [
  "Warehouse Name",
  "Customer Name",
  "Supply SKU",
  "Demand SKU",
];
export const NewConversionDropDownMasters = [
  "WarehouseMasterMapping",
  "CustomerMasterMapping",
  "SKUMasterMapping",
  "DemandSKUMapping",
];
export const NewConversionDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
  customer_name: "CustomerMasterMapping",
  supply_sku: "SKUMasterMapping",
  demand_sku: "DemandSKUMapping",
};

export const NewConversionItemMasterFilter = [
  "Channel",
  "Customer Name",
  "SOP Category",
];
export const NewConversionIntRows = [
  "Conversion Proportion",
  "Volume Multiplier",
];

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const wHConversionNonEditableRows = ["Split Id"];
export const wHConversionColumnsDatabase = [
  "SplitId",
  "customer_code",
  "SOP_Category",
];
export const wHConversionRows = [
  "Split Id",
  "Customer Name",
  "SOP Category",
  "Warehouse Name",
];
export const wHConversionRowMapping = {
  "Split Id": "SplitId",
  "Warehouse Name": "warehouse_name",
  "Customer Name": "customer_name",
  "SOP Category": "SOP_Category",
};
export const wHConversionDependenciesMapping = {
  "Warehouse Name": ["warehouse_code"],
  "Customer Name": ["customer_code"],
  "SOP Category": [],
};
export const wHConversionDropDownRows = [
  "Warehouse Name",
  "Customer Name",
  "SOP Category",
];
export const wHConversionDropDownMasters = [
  "WarehouseMasterMapping",
  "CustomerMasterMapping",
  "SOP_CategoryMapping",
];
export const wHConversionDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
  customer_name: "CustomerMasterMapping",
  SOP_Category: "SOP_CategoryMapping",
};

export const wHConversionItemMasterFilter = [
  "Channel",
  "Customer Name",
  "SOP Category",
];
// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const InventorySKUNonEditableRows = [
  "ID",
  "Warehouse Country",
  "Status",
];
export const InventorySKUColumnsDatabase = [
  "id",
  "warehouse_code",
  "facility_name",
  "lot_no",
];
export const InventorySKUInRows = [
  "Facility Name",
  "Lot Number",
  "Warehouse Name",
  "Warehouse Country",
  "Status",
];
export const InventorySKURowMapping = {
  ID: "id",
  "Facility Name": "facility_name",
  "Lot Number": "lot_no",
  "Warehouse Name": "warehouse_name",
  "Warehouse Country": "warehouse_country",
  Status: "Active",
};
export const InventorySKUDependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country", "Active"],
};
export const InventorySKUDropDownRows = ["Warehouse Name"];
export const InventorySKUDropDownMasters = ["WarehouseMasterMapping"];
export const InventorySKUDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
};
export const InventorySKUIntRows = [];

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const OrderWHMappingNonEditableRows = ["ID", "Warehouse Country"];
export const OrderWHMappingColumnsDatabase = ["id", "warehouse_code", "WH"];
export const OrderWHMappingInRows = [
  "Fulfilment Warehouse Name",
  "Warehouse Name",
  "Warehouse Country",
];
export const OrderWHMappingRowMapping = {
  ID: "id",
  "Fulfilment Warehouse Name": "WH",
  "Warehouse Name": "warehouse_name",
  "Warehouse Country": "warehouse_country",
};
export const OrderWHMappingDependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country"],
};
export const OrderWHMappingropDownRows = ["Warehouse Name"];
export const OrderWHMappingDropDownMasters = ["WarehouseMasterMapping"];
export const OrderWHMappingDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
};
export const OrderWHIntRows = [];

// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const fullOrderSkuNonEditableRows = ["ID"];
export const fullOrderSkuColumnsDatabase = [
  "id",
  "warehouse_country",
  "order_sku",
];
export const fullOrderSkuRows = ["Warehouse Country", "Order SKU"];
export const fullOrderSkuRowMapping = {
  ID: "id",
  "Warehouse Country": "warehouse_country",
  "Order SKU": "orderSKU",
};
export const fullOrderSkuDependenciesMapping = {};
export const fullOrderSkuDropDownRows = [];
export const fullOrderSkuDropDownMasters = [];
export const fullOrderSkuDropDownMasterColumnsMapping = {};
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const DiscontinuedWarehouseSkuNonEditableRows = ["Split ID"];
export const DiscontinuedWarehouseColumnsDatabase = [
  "Discontinuated",
  "replace_with",
  "Proportion",
  "splitId",
];
export const DiscontinuedWarehouseSkuRows = [
  "Split ID",
  "Discontinued Warehouse",
  "Replacing Warehouse",
  "Proportion",
];
export const DiscontinuedWarehouseSkuRowMapping = {
  "Split ID": "splitId",
  "Discontinued Warehouse": "Discontinuated",
  "Replacing Warehouse": "warehouse_name",
  Proportion: "Proportion",
};
export const DiscontinuedWarehouseDependenciesMapping = {
  "Replacing Warehouse": ["replace_with"],
};
export const DiscontinuedWarehouseDropDownRows = ["Replacing Warehouse"];
export const DiscontinuedWarehouseDropDownMasters = ["WarehouseMasterMapping"];
export const DiscontinuedWarehouseDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
};
export const DiscontinuedWarehouseCustomColumnWidth = {
  "Replacing Warehouse": 200,
};
// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const WarehouseSpaceNonEditableRows = ["ID"];
export const WarehouseSpaceColumnsDatabase = [
  "id",
  "warehouse_code",
  "supply_sku",
  "cost_per_bag",
  "bags_per_carton",
  "bags_per_pallet",
  "square_feet_per_pallet",
  "Comment",
];
export const WarehouseSpaceRows = [
  "Warehouse Name",
  "Supply SKU",
  "Cost per Bag",
  "Bags per Carton",
  "Bags Per Pallet",
  "Sqaure Feet Per Pallet",
  "Comment",
];
export const WarehouseSpaceRowMapping = {
  ID: "id",
  "Warehouse Name": "warehouse_name",
  "Supply SKU": "supply_sku",
  "Bags per Carton": "bags_per_carton",
  "Cost per Bag": "cost_per_bag",
  "Bags Per Pallet": "bags_per_pallet",
  "Sqaure Feet Per Pallet": "square_feet_per_pallet",
  Comment: "Comment",
};

export const WarehouseSpaceDependenciesMapping = {
  "Warehouse Name": ["warehouse_code"],
  "Supply SKU": [],
};

export const WarehouseSpaceDropdownRows = ["Warehouse Name", "Supply SKU"];
export const WarehouseSpaceDropDownMasters = [
  "WarehouseMasterMapping",
  "SupplySkuMapping",
];
export const WarehouseSpaceIntRows = [
  "Cost per Bag",
  "Bags per Carton",
  "Bags Per Pallet",
  "Sqaure Feet Per Pallet",
];
export const WarehouseSpaceDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
  supply_sku: "SupplySkuMapping",
};
// export const WarehouseSpaceFilter = ["Channel", "Customer Name", "SOP Category"]

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const WarehouseCapacityConstraintsNonEditableRows = ["ID"];
export const WarehouseCapacityConstraintsColumnsDatabase = [
  "id",
  "warehouse_code",
  "Proportion",
];
export const WarehouseCapacityConstraintsSkuRows = ["Warehouse Name"];
export const WarehouseCapacityConstraintsRowMapping = {
  ID: "id",
  "Warehouse Name": "warehouse_name",
};
export const WarehouseCapacityConstraintsDependenciesMapping = {
  "Warehouse Name": ["warehouse_code"],
};
export const WarehouseCapacityConstraintsDropDownRows = ["Warehouse Name"];
export const WarehouseCapacityConstraintsDropDownMasters = [
  "WarehouseMasterMapping",
];
export const WarehouseCapacityConstraintsDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
};
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const WOSExceptionNonEditableRows = ["ID", "Warehouse Country"];
export const WOSExceptionColumnsDatabase = [
  "id",
  "warehouse_code",
  "SOP_Category",
  "supply_sku",
  "channel",
  "start_week",
  "end_week",
  "wos_exception",
  "date_entered",
  "Planner",
];
export const WOSExceptionSkuRows = [
  "Channel",
  "S&OP Category",
  "Warehouse Name",
  "Warehouse Country",
  "Supply SKU",
  "Start Week",
  "End Week",
  "WOS Exception",
  "Date Entered",
  "Planner",
];
export const WOSExceptionDateColumns = [
  "start_week",
  "end_week",
  "date_entered",
];
export const WOSExceptionRowMapping = {
  ID: "id",
  "Warehouse Name": "warehouse_name",
  "Warehouse Country": "warehouse_country",
  Channel: "channel",
  "S&OP Category": "SOP_Category",
  "Supply SKU": "supply_sku",
  "Start Week": "start_week",
  "End Week": "end_week",
  "WOS Exception": "wos_exception",
  "Date Entered": "date_entered",
  Planner: "Planner",
  Comment: "comment",
};
export const WOSExceptionDependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country"],
  "Supply SKU": [],
  "S&OP Category": ["supply_sku"],
  Channel: [],
};
export const WOSExceptionChildDropDown = ["supply_sku"];
export const WOSExceptionParentDropDown = ["SOP_CategoryMasterMapping"];
export const WOSExceptionParentChildMapping = {
  SOP_CategoryMasterMapping: "supply_sku",
};
export const WOSExceptionChildMasterMapping = {
  supply_sku: "SOP_CategoryMasterMapping",
};
export const WOSExceptionDropDownRows = [
  "Warehouse Name",
  "S&OP Category",
  "Supply SKU",
  "Channel",
];
export const WOSExceptionDropDownMasters = [
  "WarehouseMasterMapping",
  "SOP_CategoryMasterMapping",
  "ChannelMapping",
];
export const WOSExceptionDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
  SOP_Category: "SOP_CategoryMasterMapping",
  channel: "ChannelMapping",
};
export const WOSExceptionColumnsDropDownMapping = {
  WarehouseMasterMapping: "warehouse_name",
  SOP_CategoryMasterMapping: "SOP_Category",
  ChannelMapping: "channel",
};
export const WOSExceptionmanualDropdownRows = {
  channel: ["All", "B2B", "B2C"],
};
export const WOSExceptionBlankRows = ["start_week", "wos_exception", "comment"];
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const ContainerCapacityNonEditableRows = ["ID"];
export const ContainerCapacityColumnsDatabase = [
  "id",
  "supply_sku",
  "bags_per_container",
  "sku_leadtime_in_weeks",
  "planning_level",
  "comment",
];
export const ContainerCapacityRows = [
  "Supply SKU",
  "SKU LeadTime(Weeks)",
  "Bags Per Container",
  "Planning Level",
  "Comment",
];
export const ContainerCapacityRowMapping = {
  ID: "id",
  "SKU LeadTime(Weeks)": "sku_leadtime_in_weeks",
  "Supply SKU": "supply_sku",
  "Bags Per Container": "bags_per_container",
  "Planning Level": "planning_level",
  Comment: "comment",
};
export const ContainerCapacityDependenciesMapping = {
  "Planning Level": [],
  "Supply SKU": [],
};
export const ContainerCapacityBlankRows = [
  "comment",
  "sku_leadtime_in_weeks",
  "bags_per_container",
];
export const ContainerCapacityDropdownRows = ["Supply SKU", "Planning Level"];
export const ContainerCapacityDropDownMasters = [
  "PlanningLevelMapping",
  "SupplySkuMasterMapping",
];
export const ContainerCapacityDropDownMasterColumnsMapping = {
  supply_sku: "SupplySkuMasterMapping",
  planning_level: "PlanningLevelMapping",
};
export const ContainerCapacityIntRows = [
  "SKU LeadTime(Weeks)",
  "Bags Per Container",
];

// export const ContainerCapacityItemMasterFilter = ["Channel", "Customer Name", "SOP Category"]
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const ProductSupplySkuNonEditableRows = ["ID", "S&OP Category Name"];
export const ProductSupplySkuColumnsDatabase = [
  "id",
  "supply_sku",
  "product",
  "comment",
];
export const ProductSupplySkuRows = [
  "Product",
  "Supply SKU",
  "S&OP Category Name",
  "Comment",
];
export const ProductSupplySkuRowMapping = {
  ID: "id",
  Product: "product",
  "Supply SKU": "supply_sku",
  "S&OP Category Name": "SOPCategoryName",
  Comment: "comment",
};
export const ProductSupplySkuDependenciesMapping = {
  "Supply SKU": ["SOPCategoryName"],
};
export const ProductSupplySkuBlankRows = ["comment"];
export const ProductSupplySkuDropdownRows = ["Supply SKU"];
export const ProductSupplySkuDropDownMasters = ["SupplySkuMapping"];
export const ProductSupplySkuDropDownMasterColumnsMapping = {
  supply_sku: "SupplySkuMapping",
};
export const ProductSupplySKUIntRows = [];

// export const ContainerCapacityItemMasterFilter = ["Channel", "Customer Name", "SOP Category"]
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const LocationWarehouseNonEditableRows = ["ID", "Warehouse Country"];
export const LocationWarehouseColumnsDatabase = [
  "id",
  "location_address",
  "warehouse_code",
  "comment",
];
export const LocationWarehouseRows = [
  "Location",
  "Warehouse Name",
  "Warehouse Country",
  "Comment",
];
export const LocationWarehouseRowMapping = {
  ID: "id",
  "Warehouse Name": "warehouse_name",
  "Warehouse Country": "warehouse_country",
  Location: "location_address",
  Comment: "comment",
};
export const LocationWarehouseDependenciesMapping = {
  "Warehouse Name": ["warehouse_code", "warehouse_country"],
};
export const LocationWarehouseBlankRows = ["comment"];
export const LocationWarehouseDropdownRows = ["Warehouse Name"];
export const LocationWarehouseDownMasters = ["WarehouseMasterMapping"];
export const LocationWarehouseDropDownMasterColumnsMapping = {
  warehouse_name: "WarehouseMasterMapping",
};
export const LocaltionWarehouseIntRows = [];

// export const ContainerCapacityItemMasterFilter = ["Channel", "Customer Name", "SOP Category"]
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const SafetyStockNonEditableRows = ["Policy ID"];
export const SafetyStockColumnsDatabase = [
  "policy_id",
  "SS_Name",
  "till_week_ending_date",
  "min_ss_policy"
];
export const SafetyStockSkuRows = [
  "Policy ID",
  "Policy Name",
  "Till Week Ending",
  "WOS"
];
export const SafetyStockSkuRowMapping = {
  "Policy ID": "policy_id",
  "Policy Name": "SS_Name",
  "Till Week Ending": "till_week_ending_date",
  WOS: "min_ss_policy",
  Comment: "comment",
};
export const SafetyStockDependenciesMapping = {};
export const SafetyStockDropDownRows = [];
export const SafetyStockDropDownMasters = [];
export const SafetyStockDropDownMasterColumnsMapping = {};
export const SafetyStockCustomColumnWidth = {};

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const TrackingExceptionNonEditableRows = ["ID","Warehouse Name"];

export const TrackingExceptionHandlingRows = [
  "PO #",
  "Container #",
  "Supply SKU",
  "New Location",
  "Warehouse Name",
  "Quantity",
  "WMS ADD",
  "WMS Bags Recieved",
  "Adjusted Receive By",
  "Comment"
];
export const TrackingExceptionHandlingRowsDatabase = [
  "id",
  "po_number",
  "container_number",
  "supply_sku",
  "location_address",
  "quantity",
  "wms_add",
  "wms_bags_recieved",
  "adjust_recieved_by_date",
  "comment"
];
export const TrackingExceptionHandlingRowsMapping = {
  "ID":"id",
  "PO #":"po_number",
  "Container #":"container_number",
  "Supply SKU":"supply_sku",
  "New Location":"location_address",
  "Warehouse Name":"warehouse_name",
  "Quantity":"quantity",
  "WMS ADD":"wms_add",
  "WMS Bags Recieved":"wms_bags_recieved",
  "Adjusted Receive By":"adjust_recieved_by_date",
  "Comment":"comment"
};

export const TrackingExceptionHandlingGroup = {};
export const TrackingExcdependenciesMapping = {
  "New Location": ["warehouse_name"],
  "Supply SKU":[]

};
export const TrackingExcDropDownRows = [
  "New Location",
  "Supply SKU"
];
export const TrackingExcDropDownMasters = [
  "LocationNameMapping",
  "SKUMasterMapping"
];
export const TrackingExcDropDownMasterColumnsMapping = {
  'location_address': "LocationNameMapping",
  'supply_sku': "SKUMasterMapping"
};
export const TrackingExcFilter = [];
export const TrackingExcIntRows = ["Quantity","WMS Bags Recieved"];
export const TrackingExcDateRows = ["WMS ADD","Adjusted Receive By"];
export const TrackingExcBlankRows = ["location_address",
  "quantity",
  "wms_add",
  "wms_bags_recieved",
  "adjust_recieved_by_date",
  "comment"]

//------------------------------------------------------------------------------------------------------------------

export const ResCcontainerExceptionNonEditableRows = ["ID"];

export const ResCcontainerExceptionRows = [
  "PO #",
  "Container #",
  "Comment"
];
export const ResCcontainerExceptionRowsDatabase = [
  "id",
  "po_number",
  "container_number",
  "comment"
];
export const ResCcontainerExceptionRowsMapping = {
  "ID":"id",
  "PO #":"po_number",
  "Container #":"container_number",
  "Comment":"comment"
};

export const ResCdecontainerExceptionGroup = {};
export const ResCcontainerExceptiondependenciesMapping = {
};
export const ResCcontainerExceptionDropDownRows = [
];
export const ResCcontainerExceptionDropDownMaster=[];
export const ResCcontainerExceptionColumnsMapping = {
};
export const ResCcontainerExceptionFilter = [];
export const ResCcontainerExceptionIntRows = [];
export const ResCcontainerExceptionDateRows = [];
export const ResCcontainerExceptionBlankRows = ["comment"]


//------------------------------------------------------------------------------------------------------------------

export const ClosedUnmatchedWMSNonEditableRows = ["ID"];

export const ClosedUnmatchedWMSRows = [
  "PO #",
  "Container #",
  "Warehouse Name",
  "Supply SKU",
  "Comment"
];
export const ClosedUnmatchedWMSRowsDatabase = [
  "id",
  "po_number",
  "container_number",
  "supply_sku",
  "warehouse_code",
  "comment"
];
export const ClosedUnmatchedWMSRowsMapping = {
  "ID":"id",
  "PO #":"po_number",
  "Container #":"container_number",
  "Supply SKU":"supply_sku",
  "Warehouse Name":"warehouse_name",
  "Comment":"comment"
};

export const ClosedUnmatchedWMSGroup = {};
export const ClosedUnmatchedWMSdependenciesMapping = {
  "Warehouse Name": ["warehouse_code"],
  "Supply SKU":[]

};
export const ClosedUnmatchedWMSDropDownRows = [
  "Warehouse Name",
  "Supply SKU"
];
export const ClosedUnmatchedWMSDropDownMasters = [
  "WarehouseNameMapping",
  "SKUMasterMapping"
];
export const ClosedUnmatchedWMSColumnsMapping = {
  'warehouse_name': "WarehouseNameMapping",
  'supply_sku': "SKUMasterMapping"
};
export const ClosedUnmatchedWMSFilter = [];
export const ClosedUnmatchedWMSIntRows = [];
export const ClosedUnmatchedWMSDateRows = [];
export const ClosedUnmatchedWMSBlankRows = ["comment","po_number","container_number"]


