import DropDownTables from "../WarehouseSpace/WarehouseSpaceContentTabPanel";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Flex, TabPanels,Box } from "@chakra-ui/react";
import { Container } from "@chakra-ui/react";
import { Tabs } from "@chakra-ui/react";
import { Tab } from "@chakra-ui/react";
import { TabList, Stack } from "@chakra-ui/react";
import useFetch from "../../../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../../../Spinner";
import { ClosedUnmatchedWMSBlankRows, ClosedUnmatchedWMSColumnsMapping, ClosedUnmatchedWMSdependenciesMapping, ClosedUnmatchedWMSDropDownMasters, ClosedUnmatchedWMSDropDownRows, ClosedUnmatchedWMSGroup, ClosedUnmatchedWMSIntRows, ClosedUnmatchedWMSNonEditableRows, ClosedUnmatchedWMSRows, ClosedUnmatchedWMSRowsDatabase, ClosedUnmatchedWMSRowsMapping, ResCcontainerExceptionBlankRows, ResCcontainerExceptionColumnsMapping, ResCcontainerExceptiondependenciesMapping, ResCcontainerExceptionDropDownMaster, ResCcontainerExceptionFilter, ResCcontainerExceptionIntRows, ResCcontainerExceptionNonEditableRows, ResCcontainerExceptionRows, ResCcontainerExceptionRowsDatabase, ResCcontainerExceptionRowsMapping, ResCdecontainerExceptionGroup, TrackingExcBlankRows, TrackingExcDateRows, TrackingExcdependenciesMapping, TrackingExcDropDownMasterColumnsMapping, TrackingExcDropDownMasters, TrackingExcDropDownRows, TrackingExceptionHandlingGroup, TrackingExceptionHandlingRows, TrackingExceptionHandlingRowsDatabase, TrackingExceptionHandlingRowsMapping, TrackingExceptionNonEditableRows, TrackingExcIntRows } from "../RegionalCanvasHelper";

export default function MultiTabPanel({
  setDataSaved,
  ActiveEnv,
  updateURL,
  deleteURL,
  deletenabled = true,
}) {
  const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"));
  const {pathname} = useLocation();
  const [id_sheet] = useState(pathname ? parseInt(pathname.split("-")[1]) : 0);
  const [{loading,error}, fetchData1] = useFetch();
  const [,fetchData2] = useFetch();
  const [,fetchData3] = useFetch();

  return(
  <Flex flex="1" flexDirection="column">
     <Container
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        hidden={!(loading||!ActiveEnv)}
      >
        <Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
        >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box>
    </Container>
    
    <Container maxW="container.xxl" py={8} hidden={loading}>
      <Stack spacing={8}>
        <Tabs variant="enclosed" bg="white" boxShadow="md">
          <TabList>
            <Tab>
                Tracking Exception Handling
            </Tab>
            <Tab>
                Closed Unmatched WMS Line Item
            </Tab>
            <Tab>
                Resolved Container Exception
            </Tab>
          </TabList>
          <TabPanels>
            <DropDownTables
              setDataSaved={setDataSaved}
              ActiveEnv={ActiveEnv}
              CustomerMasterRows={TrackingExceptionHandlingRows}
              CustomerRowMapping={TrackingExceptionHandlingRowsMapping}
              SupplySkuNonEditableRows={TrackingExceptionNonEditableRows}
              SupplySkuColumnsDatabase={TrackingExceptionHandlingRowsDatabase}
              DropDownRows={TrackingExcDropDownRows}
              DropDownMasters={TrackingExcDropDownMasters}
              DropDownMasterColumnsMapping={TrackingExcDropDownMasterColumnsMapping}
              dependenciesMapping={TrackingExcdependenciesMapping}
              DropDownMasterIntRows={TrackingExcIntRows}
              DropDownMasterDateRows={TrackingExcDateRows}
              blankRows={TrackingExcBlankRows}
              retrieveURL={"/get-data-container-tracking"}
              updateURL={"/update-master"}
              deleteURL={"/delete-master"}
              deletenabled={true}
              id_sheet={22}
              pathname={'/TrackingContainerException--1'}
              additionalNotes={["This data is used in production reporting."]}
              fetchData={fetchData1}
              disableVirtualScrolling={true}
            />
            <DropDownTables
              setDataSaved={setDataSaved}
              ActiveEnv={ActiveEnv}
              CustomerMasterRows={ClosedUnmatchedWMSRows}
              CustomerRowMapping={ClosedUnmatchedWMSRowsMapping}
              SupplySkuNonEditableRows={ClosedUnmatchedWMSNonEditableRows}
              SupplySkuColumnsDatabase={ClosedUnmatchedWMSRowsDatabase}
              DropDownRows={ClosedUnmatchedWMSDropDownRows}
              DropDownMasters={ClosedUnmatchedWMSDropDownMasters}
              DropDownMasterColumnsMapping={ClosedUnmatchedWMSColumnsMapping}
              dependenciesMapping={ClosedUnmatchedWMSdependenciesMapping}
              DropDownMasterIntRows={ClosedUnmatchedWMSIntRows}
              blankRows={ClosedUnmatchedWMSBlankRows}
              retrieveURL={'/get-data-closed-wms'}
              updateURL={"/update-master"}
              deleteURL={"/delete-master"}
              deletenabled={deletenabled}
              ItemMasterGroupPolicy={ClosedUnmatchedWMSGroup}
              id_sheet={22}
              pathname={'/ClosedUnmatchedWMS--1'}
              additionalNotes={["This data is used in production reporting."]}
              fetchData={fetchData2}
              disableVirtualScrolling={true}
            />
            <DropDownTables
              setDataSaved={setDataSaved}
              ActiveEnv={ActiveEnv}
              CustomerMasterRows={ResCcontainerExceptionRows}
              CustomerRowMapping={ResCcontainerExceptionRowsMapping}
              SupplySkuNonEditableRows={ResCcontainerExceptionNonEditableRows}
              SupplySkuColumnsDatabase={ResCcontainerExceptionRowsDatabase}
              DropDownRows={ResCcontainerExceptionFilter}
              DropDownMasters={ResCcontainerExceptionDropDownMaster}
              DropDownMasterColumnsMapping={ResCcontainerExceptionColumnsMapping}
              dependenciesMapping={ResCcontainerExceptiondependenciesMapping}
              DropDownMasterIntRows={ResCcontainerExceptionIntRows}
              blankRows={ResCcontainerExceptionBlankRows}
              retrieveURL={'/get-data-resolved-containers'}
              updateURL={"/update-master"}
              deleteURL={"/delete-master"}
              deletenabled={deletenabled}
              ItemMasterGroupPolicy={ResCdecontainerExceptionGroup}
              id_sheet={22}
              pathname={'/ResolvedUnmatchedContainer--1'}
              additionalNotes={["This data is used in production reporting."]}
              fetchData={fetchData3}
              disableVirtualScrolling={true}
            />
            
            </TabPanels>
        </Tabs>
      </Stack>
    </Container>
  </Flex>
  )
};
