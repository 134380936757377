import { ChakraProvider } from "@chakra-ui/react";
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ContainerCapacity from './components/Dashboard/AnalyzerLevel/ContainerCapacity/ContainerCapacity';
import AnalyzerView from './components/Dashboard/AnalyzerLevel/CustomerMaster';
import CustomerWarehouseSplit from './components/Dashboard/AnalyzerLevel/CustomerWarehouseSplit/CustomerWarehouseSplit';
import DemandSKUTable from './components/Dashboard/AnalyzerLevel/DemandSKUTable';
import DiscontinuedWarehouse from './components/Dashboard/AnalyzerLevel/DiscontinuedWarehouse/DiscontinuedWarehouse';
import InventoryWarehouseMapping from './components/Dashboard/AnalyzerLevel/InventoryWarehouseMapping';
import ItemMaster from './components/Dashboard/AnalyzerLevel/ItemMaster';
import LocationWarehouseMapping from './components/Dashboard/AnalyzerLevel/LocationWarehouseMapping/LocationWarehouseMapping';
import NewConversionMaster from './components/Dashboard/AnalyzerLevel/NewConversionMaster';
import FullfillmentOrderSkuMapping from './components/Dashboard/AnalyzerLevel/OrderSKUFullfillmentSKU/FulfillmentOrderSkuMapping';
import ProductSupplySkuMapping from './components/Dashboard/AnalyzerLevel/ProductSupplySkuMapping/ProductSupplySkuMapping';
import SOPCategoryMaster from './components/Dashboard/AnalyzerLevel/SOPCategoryMaster';
import SafetyStockPolicy from "./components/Dashboard/AnalyzerLevel/SafetyStockPolicy/SafetyStockPolicy";
import SupplyMaster from './components/Dashboard/AnalyzerLevel/SupplyMaster';
import WarehouseCapacityConstraints from './components/Dashboard/AnalyzerLevel/WarehouseCapacityConstraints/WarehouseCapacityConstraints';
import WarehouseMaster from './components/Dashboard/AnalyzerLevel/WarehouseMaster/WarehouseMaster';
import WarehouseORderWHMapping from './components/Dashboard/AnalyzerLevel/WarehouseOrderWHMapping';
import WarehouseSKUMapping from './components/Dashboard/AnalyzerLevel/WarehouseSKUMapping';
import WarehouseSpace from './components/Dashboard/AnalyzerLevel/WarehouseSpace/WarehouseSpace';
import Login from "./components/Login/Login";
import SafetyStockAssignment from "./components/Dashboard/AnalyzerLevel/SafetyStockAssignment/SafetyStockAssignment";
import PlanvidaDashboard from "./components/Dashboard/AnalyzerLevel/PlanvidaDashboard";
import ContainerTracking from "./components/Dashboard/AnalyzerLevel/ContainerTracking/ContainerTracking";


function App() {
	return (
		<ChakraProvider>
			<BrowserRouter basename="/">
				<div className="App">
					<Routes>
						<Route index path="/" element={<Login />} />
						{/* <Route path="/dashboard" element={<Dashboard />} /> */}
						{/* <Route path="/auth" element={<Auth />} /> */}
						{/* <Route path="/forecastReport" element={<DashboardReport />} /> */}
						{/* <Route index path="/dashboard" element={<TokenTest />} /> */}
						<Route path="/CustomerMaster-2" element={<AnalyzerView />} />
						<Route path="/WarehouseMaster-3" element={<WarehouseMaster />} />
						<Route path="/S&OPCategoryMaster-4" element={<SOPCategoryMaster/>} />
						<Route path="/ItemMaster-1" element={<ItemMaster/>} />
						<Route path="/SupplySKUMaster-5" element={<SupplyMaster/>} />
						<Route path="/NewConversionMaster-6" element={<NewConversionMaster/>} />
						<Route path="/SafetyStockPolicy-20" element={<SafetyStockPolicy/>} />
						<Route path="/CustomerWarehouseSplit-7" element={<CustomerWarehouseSplit/>} />
						<Route path="/DemandSKUTable-8" element={<DemandSKUTable/>} />
						<Route path="/WarehouseSKUMapping-9" element={<WarehouseSKUMapping/>} />
						<Route path="/InventoryWarehouseMapping-10" element={<InventoryWarehouseMapping/>} />
						<Route path="/OrderToFullfillmentSKUMapping-11" element={<FullfillmentOrderSkuMapping/>} />
						<Route path="/OrderWarehouseMapping-12" element={<WarehouseORderWHMapping/>} />
						<Route path="/DiscontinuatedWarehouse-13" element={<DiscontinuedWarehouse/>} />
						<Route path="/WarehouseSpace-14" element={<WarehouseSpace/>} />
						<Route path="/WarehouseCapacityConstraints-15" element={<WarehouseCapacityConstraints/>} />
						{/* <Route path="/WOSExceptions-16" element={<WOSException/>} /> */}
						<Route path="/ContainerCapacity-17" element={<ContainerCapacity/>} />
						<Route path="/ProductSupplySkuMapping-18" element={<ProductSupplySkuMapping/>} />
						<Route path="/LocationToWarehouseMapping-19" element={<LocationWarehouseMapping/>} />
						<Route path="/SafetyStockAssignment-21" element={<SafetyStockAssignment />} />
						<Route path="/PlanvidaDashboard" element={<PlanvidaDashboard />} />
						<Route path="/ContainerTrackingAdjustment-22" element={<ContainerTracking/>} />
						{/* <Route path="/regionalDashboardReport" element={<RegionalDashboardReport />} /> */}
						{/* <Route path="/*" element={<Navigate to="/" replace={true} />} /> */}
					</Routes>
				</div>
			</BrowserRouter>
		</ChakraProvider>
	);
}

// function TokenTest() {
//     const [{ data, loading, error }, fetchData] = useFetch();

//     useEffect(() => {
//         fetchData("/authorizedPing", {method: "GET"});
//     }, [])
    
//     return <div>{data.message ? data.message : "loading1..."}</div>
// }

export default App;
