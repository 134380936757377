import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetchWithMsal";
import { CustomSpinner } from "../Spinner";
import './ChangeLog.css';

function ChangeLog({ PageName, isOpen, onClose, onOpen }) {

    const [{ loading }, fetchData] = useFetch([]);
    const [cellStyle1,]=useState({fontSize: 12})
    const [changeLog, setChangeLog] = useState([]);
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, ] = useState([
        {
            headerName: "ID",
            filter: true,
            field: "change_id",
            flex: 0.5,
            cellStyle: cellStyle1
        },
        {
            headerName: "Table",
            filter: true,
            field: "table_name",
            flex: 2.5,
            cellStyle: cellStyle1
        },
        {
            headerName: "User",
            filter: true,
            field: "user_id",
            flex: 1,
            cellStyle: cellStyle1
        },
        {
            headerName: "Description",
            filter: true,
            field: "change_description",
            flex: 3,
            wrapText: true,
            autoHeight: true,
            cellStyle: cellStyle1
        },
        {
            headerName: "Type",
            filter: true,
            field: "change_type",
            flex: 1,
            cellStyle: cellStyle1
        },
        {
            headerName: "Comment",
            filter: true,
            field: "comment",
            flex: 2,
            autoHeight: true,
            wrapText: true,
            cellStyle: cellStyle1
        },
        {
            headerName: "Date",
            filter: true,
            field: "update_time",
            flex: 1,
            valueFormatter:(p)=>{
                if (p.value===null||p.value===""){
                    return ""
                }
                return (new Date(p.value)).toLocaleDateString()
            },
            autoHeight: true,
            wrapText: true,
            cellStyle: cellStyle1

              
        },
        {
            headerName: "Time",
            filter: true,
            field: "update_time",
            flex: 1,
            valueFormatter:(p)=>{
                if (p.value===null||p.value===""){
                    return ""
                }
                return (new Date(p.value)).toLocaleTimeString()
            },
            autoHeight: true,
            wrapText: true,
            cellStyle: cellStyle1

              
        }
    ]);



    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if (isOpen === true) {
            fetchData(`/get-data-change_log?PageName=${PageName}`, { method: "GET", signal }).then((data) => {
                setChangeLog(Object.values(data["ExistingTable"]))
            })
        }
        return () => {
            controller.abort()
        }
    }, [fetchData, setChangeLog,PageName,isOpen])



    return (


        <>
            <Button onClick={onOpen}>Open Modal</Button>
            <Modal blockScrollOnMount={false} size={"lg"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW={"90%"}  marginTop={"8%"} maxH={"70vh"}>
                    <ModalHeader>ChangeLog</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {!loading &&
                            <div
                                className="ag-theme-quartz" // applying the grid theme
                                style={{ height: "45vh"}} // the grid will fill the size of the parent container
                            > <AgGridReact
                                    rowData={changeLog}
                                    columnDefs={colDefs}
                                    headerHeight={30}
                                /></div>}
                        {loading &&
                            <CustomSpinner text="Loading Your Data.. Please Wait" />
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChangeLog;