import { Card, CardBody, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spacer, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { pdfMapping } from "../../../Constants";


export default function RegionalLeaderTable({ name }) {
    const { isOpen:isModalOpen, onOpen:onModalOpen, onClose:onModalClose } = useDisclosure()
  return (
    <Flex>
      {/* <Card
        textAlign={"left"}
        marginTop={0}
        borderBottom="2px solid white"
        bg="blackAlpha.900"
        color="white"
        w="60vh"
      >
        <CardBody p={3} px={5}>
          <Text as={"b"}>Sheet : {name}</Text>
        </CardBody>
      </Card>
      <Spacer /> */}
      <Tooltip label="Documentation" placement='auto'>
        <Card bg="white" color="grey" onClick={onModalOpen}>
          <CardBody p={3}>
            <Text p={2} as="b">
              ?
            </Text>
          </CardBody>
        </Card>
      </Tooltip>

      <Modal onClose={onModalClose} isOpen={isModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent maxWidth={"850px"}>
          <ModalHeader>Documentation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <iframe
              title="doc"
              src={pdfMapping[name]}
              height={"500px"}
              width={"800px"}
            />
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={onModalClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Flex>
  );
}