import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Container, Flex, HStack, Input, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip } from "@chakra-ui/react";
import { ReactGrid } from '@silevis/reactgrid';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { findMaxKey } from "../../../../Constants";
import useFetch from '../../../../hooks/useFetchWithMsal';
import SaveButton from '../../../Save';
import { CustomSpinner } from "../../../Spinner";
import { FormatDateToMonthYear1, getCellClassName } from '../RegionalCanvasHelper';
import RegionalLeaderTable from "../RegionalLeaderTable";
// import TextWrapRenderer from './TextWrapRenderer';
// import * as FileSaver from "file-saver";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsCalendar2X, BsCardList } from "react-icons/bs";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineCancel, MdOutlineTableRows } from "react-icons/md";
import ChangeLog from "../../../ChangeLog/ChangeLog";
import CustomAlertDialogBox from "../../../CustomAlertDialogBox/CustomAlertDialogBox";
import '../style.scss';

export default function FullfillmentOrderSkuMappingContent({ setDataSaved, ActiveEnv, CustomerMasterRows1, CustomerRowMapping1, SupplySkuNonEditableRows, SupplySkuColumnsDatabase, DropDownRows, DropDownMasters, DropDownMasterColumnsMapping, dependenciesMapping, retrieveURL, updateURL, deleteURL }) {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const { pathname } = useLocation()
    const [int1, setInt] = useState(0)
    const [{  loading, error}, fetchData] = useFetch();
    const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"))
    const [id_sheet,] = useState(pathname ? parseInt(pathname.split('-')[1])  : 0)
    const navigate = useNavigate()
    const [dataGrouped, setDataGrouped] = useState({})
    const [dataGroupedOriginal, setDataGroupedOriginal] = useState({})
    const [DropDownMaster, setDropDownMaster] = useState({})
    const arrayofkeys = Object.keys(dataGrouped)
    const [maxID, setMaxID] = useState(arrayofkeys.length === 0 ? 0 : findMaxKey(dataGrouped))
    const [maxIDInDB, setMaxIDInDB] = useState(arrayofkeys.length === 0 ? 0 :findMaxKey(dataGrouped))
    const [datesOriginal,setDatesOriginal] = useState([])
    const [,updateData] = useFetch([])
    const [,deleteData] = useFetch([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [changesTracker, setChangesTracker] = useState({});
    const toast = useToast();
    const toastIdRef = useRef();
    const [Dates, setDates] = useState([])
    const [toAddDate,SetToAddDate] = useState(null)
    const [deleteCheckBox,setDeleteCheckBox] = useState(false)
    const [deleteCheckBoxColumns,setDeleteCheckBoxColumns] = useState(false)
    const [deleteTracker,setDeleteTracker] = useState({});
    const [deleteConfirmation,setDeleteConfirmation]=useState(null)
    const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
    const [comment,setComment]=useState("")
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showPrevious, setShowPrevious] = useState(false);
    console.log(changesTracker)

    const getOptionType = useCallback((DropDownMaster) => {
        if (Object.keys(DropDownMaster).length === 0) return {}
        var masterMappingDict = {}
        DropDownMasters.forEach((masterMappings) => {
            masterMappingDict[masterMappings] = Object.entries(DropDownMaster[masterMappings]).map(([key, value]) => {
                return { label: `${key}`, value: `${key}` }
            })
        })
        return masterMappingDict
    }, [DropDownMasters])

    const [optionType, setOptionType] = useState({})

    const handleChanges = (changes) => {
        const CustomerMasterRows = [...CustomerMasterRows1, ...Dates]
        const CustomerRowMapping = CustomerRowMapping1
        Dates.forEach((date_value) => {
            CustomerRowMapping[date_value] = 'fullfillment_sku_'+date_value
        })
        setInt(int1 + 1)
           
            if ((changes[0].newCell.text === "" || changes[0].newCell.text === null) && Dates.findIndex((value)=>value===changes[0].columnId) === -1) {
                const toastSettings = {
                    description: changes[0].columnId + " cannot be left blank",
                    status: 'error',
                    isClosable: true,

                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
            } else if (changes[0].type === 'checkbox' && changes[0].newCell.checked !== changes[0].previousCell.checked) {
                setRows((rowsTemp) => {
                    let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId)
                    dataRow["cells"][columns.findIndex((columnId) => columnId["columnId"] === changes[0]["columnId"])]["checked"] = changes[0].newCell.checked
                    return [...rowsTemp]
                })
                if (Dates.findIndex((value)=>value===changes[0].columnId)!==-1) {
                    setDeleteTracker((prevData)=> {
                        var updatedData=prevData
                        var columnstodelete = updatedData["columnstodelete"]?updatedData["columnstodelete"]:[]
                        if (changes[0].newCell.checked) {
                            if (columnstodelete.findIndex((value)=>value===changes[0].columnId)===-1) {
                                columnstodelete.push(changes[0].columnId)
                            }
                        } else {
                            const indexToRemove = columnstodelete.indexOf(changes[0].columnId);
                            if (indexToRemove !== -1) {
                                columnstodelete.splice(indexToRemove, 1);
                            } 
                        }
                        updatedData["columnstodelete"] =columnstodelete
                        return updatedData
                    })
                } else {
                    setDeleteTracker((prevData)=> {
                        var updatedData=prevData
                        var rowstodelete = updatedData["rowstodelete"]?updatedData["rowstodelete"]:[]
                        if (changes[0].newCell.checked) {
                            if (rowstodelete.indexOf(changes[0].rowId)===-1) {
                                rowstodelete.push(changes[0].rowId)
                            }
                        } else {
                            const indextoremove = rowstodelete.indexOf(changes[0].rowId);
                            if (indextoremove !== -1) {
                                rowstodelete.splice(indextoremove,1)
                            }
                        }
                        updatedData["rowstodelete"] =rowstodelete
                        return updatedData
                    })
                } 
        } else {
            setIsButtonDisabled(false)
            setDataSaved(false)
                var dataTemp = dataGrouped
                changes.forEach((change) => {
                    if (parseInt(change.rowId) >maxID ) {
                        let dictionaryToSet = {}
                        dictionaryToSet[`${CustomerRowMapping["ID"]}`] = parseInt(change.rowId)
                        dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text;
                        var Poer = change.columnId
                        CustomerMasterRows.filter((value) => !(value === Poer || value === "ID")).forEach((value) => {
                            dictionaryToSet[CustomerRowMapping[value]] = ""
                        })
                        dataTemp[parseInt(change.rowId)] = dictionaryToSet
                        setChangesTracker((prevChanges) => {
                            const updatedChanges = { ...prevChanges };
                            let newCustomer=change.rowId>maxIDInDB?true:false;
                            const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
                            updatedChanges[key] = dictionaryToSet
                            let newDatesAdded = []
                            if (Dates.findIndex((values)=>values===change.columnId)!==-1) {
                                newDatesAdded.push(change.columnId)
                            }
                            updatedChanges[key]['newDatesAdded'] =newDatesAdded  
                            return updatedChanges;
                        });
                    }
                    else {
                        let dictionaryToSet = dataTemp[change.rowId]
                        dictionaryToSet[CustomerRowMapping[change.columnId]] = change.newCell.text
                        dataTemp[change.rowId] = dictionaryToSet
                        setChangesTracker((prevChanges) => {
                            const updatedChanges = prevChanges ;
                            let newCustomer=change.rowId>maxIDInDB?true:false;
                            const key = `${ActiveEnv},${change.rowId},${newCustomer}`;
                            var currentDictionary = updatedChanges[key]?updatedChanges[key]:{};
                            let newDatesAdded = currentDictionary["newDatesAdded"]?currentDictionary["newDatesAdded"]:[]
                            let id_changed = currentDictionary["id_changed"]?currentDictionary["id_changed"]:[]
                            let OrderSKUChanged = currentDictionary["OrderSKUChanged"]?currentDictionary["OrderSKUChanged"]:{}
                            let old_dictionary=currentDictionary["old_dictionary"]??{}
                            if (Dates.findIndex((values)=>values===change.columnId)!==-1){
                                if ((datesOriginal.findIndex((values)=>values===change.columnId)===-1||(dataGroupedOriginal[change.rowId]?dataGroupedOriginal[change.rowId][`fullfillment_sku_${change.columnId}`]??null:null)===null)){
                                   if (newDatesAdded.findIndex((value)=>value===change.columnId)===-1){
                                    newDatesAdded.push(change.columnId)
                                   }
                                } else {
                                    let idDate = `${dataGroupedOriginal[change.rowId]["orderSKU"]}|||${dataGroupedOriginal[change.rowId]["warehouse_country"]}`
                                    if (id_changed.findIndex((value)=>value===`${idDate}_${change.columnId}`) === -1){
                                        id_changed.push(`${idDate}_${change.columnId}`)
                                        old_dictionary[`${idDate}_${change.columnId}`]=dataGroupedOriginal[change.rowId][`fullfillment_sku_${change.columnId}`]
                                    }
                                }
                            } else if (newCustomer===false) {
                                let OrderSKUChangedtoset=OrderSKUChanged[`${dataGroupedOriginal[change.rowId]["orderSKU"]}_|_${dataGroupedOriginal[change.rowId]["warehouse_country"]}`]?OrderSKUChanged[`${dataGroupedOriginal[change.rowId]["orderSKU"]}_|_${dataGroupedOriginal[change.rowId]["warehouse_country"]}`]:{}
                                OrderSKUChangedtoset[CustomerRowMapping[change.columnId]] =dictionaryToSet[CustomerRowMapping[change.columnId]]
                                OrderSKUChanged[`${dataGroupedOriginal[change.rowId]["orderSKU"]}_|_${dataGroupedOriginal[change.rowId]["warehouse_country"]}`] =OrderSKUChangedtoset
                            }
                            updatedChanges[key] = {...dictionaryToSet}
                            updatedChanges[key]["newDatesAdded"]=[...newDatesAdded]
                            updatedChanges[key]["id_changed"]=[...id_changed]
                            updatedChanges[key]["OrderSKUChanged"]={...OrderSKUChanged}
                            updatedChanges[key]['old_dictionary']={...old_dictionary}
                            return updatedChanges;
                        });
                    }
                })
                setDataGrouped(dataTemp)
                setRows(getRows(dataTemp, optionType, Dates,deleteCheckBox,deleteCheckBoxColumns, showPrevious));
                setMaxID(findMaxKey(dataTemp))
            }
        
    }

    const getColumns = useCallback((data, dates1, deleteCheckBox1, showPrevious) => {
        var dates = dates1.reduce((result,ntr)=>{
            if ((new Date(ntr)) >= (new Date())){
                result.push(ntr);

            } else if (showPrevious) {
                result.push(ntr);
            }
            return result
        }, []);
        
        var CustomerMasterRows = [...CustomerMasterRows1, ...dates]
        if (deleteCheckBox1) {
            CustomerMasterRows=['Delete',...CustomerMasterRows]
        }
        if (Object.keys(data).length === 0) return [];
        return [...CustomerMasterRows.map((column) => {
            return { columnId: column, width: (Math.max(...[column.length, ...Object.values(data).map(d => `${dates.findIndex((value)=>value===column)===-1?d[CustomerRowMapping1[column]]:d["fullfillment_sku_"+column]}`.length)]))* 10, resizable: true }
        })];
    }, [CustomerMasterRows1,CustomerRowMapping1])

    const getRows = useCallback((pivotedData, optionType, dates1, deleteCheckBox1,deleteCheckBoxColumns1, showPrevious) => {
        var dates = dates1.reduce((result,ntr)=>{
            if ((new Date(ntr)) >= (new Date())){
                result.push(ntr);

            } else if (showPrevious) {
                result.push(ntr);
            }
            return result
        }, []);
        
        var CustomerMasterRows = [...CustomerMasterRows1, ...dates];
        if (deleteCheckBox1) {
            CustomerMasterRows=['Delete',...CustomerMasterRows]
        }
        const CustomerRowMapping = CustomerRowMapping1
        dates.forEach((date_value) => {
            CustomerRowMapping[date_value] = 'fullfillment_sku_'+date_value
        })

        var maxID1 = 0
        if (Object.keys(pivotedData).length === 0) return [];
        const headerRowCells = CustomerMasterRows.map((column) => {
            return { type: "header", text: `${column}`, className: "header-cell " }
        })
        let rows = [
            {
                rowId: "header",
                cells: [...headerRowCells]
            },
        ]
        var dictionaryRow = [...rows]
        if (deleteCheckBoxColumns1) {
            var headerRowCells1 = CustomerMasterRows1.map((column) => {
                return { type: "header", text: "", className: "header-cell " }
            });

            var datesdeleteCells = dates.map((column) => {
                if (column === dates.reduce((max, arr) => {
                    if ((new Date(max) < new Date(arr))){
                        max = arr;
                    }
                    return max;
                }, dates[0])) {
                    return { type: "checkbox", checked:false, className: "header-cell ", nonEditable: true };
                } else {
                    return { type: "checkbox", checked:false, className: "header-cell " };
                }
            });

            var combine = [...headerRowCells1,...datesdeleteCells]
            if (deleteCheckBox1) { 
                combine=[{ type: "header", text: "", className: "header-cell " },...combine]
            }
       var varDeleteRows = [
            {
                rowId:"delete-header",
                cells:[...combine]
        }
        ]
        dictionaryRow=[...varDeleteRows,...rows]
        }
        var count = 1
        Object.entries(pivotedData).forEach(([parentRowName, value1]) => {
            maxID1 = parseInt(value1["id"])
            let oneSingleRow = {
                rowId: `${parentRowName}`,
                cells: [...CustomerMasterRows.map((value) => {
                    if (value==='Delete') {
                        return { type: "checkbox", className: 'pale-red-bg ' ,checked: false}
                    } else
                        return { type: "text", text: `${value1[CustomerRowMapping[value]]?value1[CustomerRowMapping[value]]:""}`, className: getCellClassName(SupplySkuNonEditableRows, value), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                    }
                )]
            }
            dictionaryRow.push(oneSingleRow)
            count = count + 1
        })
        dictionaryRow.push({
            rowId: `${maxID1+1}`, cells: [...CustomerMasterRows.map((value) => {
                if (value==='Delete') {
                    return { type: "text", className: 'editable-cell ' , text: ""}
                } else {
                    return { type: "text", text:value === "ID"?`${maxID1+1}`:"", className: getCellClassName(SupplySkuNonEditableRows, value), nonEditable: SupplySkuNonEditableRows.findIndex((value1) => value1 === value) !== -1 }
                }
                }
            )]
        })

        return dictionaryRow;
    }, [CustomerRowMapping1, SupplySkuNonEditableRows, CustomerMasterRows1])

    const handleColumnResize = (ci, width) => {
        if (Object.keys(dataGrouped).length === 0) return [];
        setColumns((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([])


    const handleDateAddition = (event) => {
        const currentDate1=new Date(toAddDate+ 'T00:00:00');

        const maxDate = datesOriginal.reduce((max,arr)=>{
            var brr = new Date(arr)
            if ((max<brr)){
                max=brr
            }
            return max
        }, new Date(datesOriginal[0]));

        if (toAddDate===null||toAddDate===''||currentDate1.getDay()!==0||currentDate1<(new Date())||currentDate1>maxDate ){
            var description = "";

            switch(true) {
                case (toAddDate === null || toAddDate === ''): {
                    description = 'Selected Date should not be null';
                    break;   
                }
                
                case (currentDate1.getDay() !== 0): {
                    description = 'Selected Date should be a Week Ending';
                    break;
                }
                        
                case (currentDate1 < (new Date())): {
                    description = 'Selected Date should be in future.';
                    break;
                }

                default: {
                    description = 'Dates should not be greater than maximum date';
                }
            }

            const toastSettings = {
                description: description,
                status: 'error',
                isClosable: true,
                duration: 2000

            }
            if (toastIdRef.current) {
                toast.update(toastIdRef.current, toastSettings)
            } else {
                toast(toastSettings)
            }

        } else {
            setDates((prevData)=> {
                const currentDate = new Date(toAddDate+ 'T00:00:00')
                const FormatDate = FormatDateToMonthYear1(currentDate)
                if (prevData.findIndex((value)=>value===FormatDate)===-1) {
                    prevData.push(FormatDate)
                }
                prevData.sort((a,b)=> {return (new Date(a).getTime()-(new Date(b).getTime()))})
                setColumns(getColumns(dataGrouped,prevData,deleteCheckBox, showPrevious));
                setRows(getRows(dataGrouped, optionType, prevData,deleteCheckBox,deleteCheckBoxColumns, showPrevious));
                return prevData
            })

            SetToAddDate(null)
        }
    }
    const handleDeleteCheckbox = ()=> {
        if(!deleteCheckBox===false){
            setDeleteTracker((prevData)=>{
                prevData["rowstodelete"]=[]
                return prevData
            })
        }
        setDeleteCheckBox(!deleteCheckBox)
        setColumns(getColumns(dataGrouped,Dates,!deleteCheckBox, showPrevious));
        setRows(getRows(dataGrouped, optionType, Dates,!deleteCheckBox,deleteCheckBoxColumns, showPrevious));
        
    }

    const handleDeleteCheckboxcolumns = ()=> {
        if(!deleteCheckBoxColumns===false){
            setDeleteTracker((prevData)=>{
                prevData["columnstodelete"]=[]
                return prevData
            })
        }
        setDeleteCheckBoxColumns(!deleteCheckBoxColumns)
        setColumns(getColumns(dataGrouped,Dates,deleteCheckBox, showPrevious));
        setRows(getRows(dataGrouped, optionType, Dates,deleteCheckBox,!deleteCheckBoxColumns, showPrevious));
    }

    const handleShowPrevious = ()=> {
        setShowPrevious(!showPrevious)
        setColumns(getColumns(dataGrouped,Dates,deleteCheckBox,!showPrevious));
        setRows(getRows(dataGrouped, optionType, Dates,deleteCheckBox,deleteCheckBoxColumns,!showPrevious))
        
    }

    useEffect(() => {
        if (!account) {
            navigate("/")
        } else {
            if (ActiveEnv && Object.keys(dataGrouped).length === 0) {
            fetchData(retrieveURL, { method: "GET" }).then((data) => {
                    setDataGrouped(data["ExistingTable"])
                    setDataGroupedOriginal(JSON.parse(JSON.stringify(data["ExistingTable"])))
                    setDropDownMaster(data["DropdownMasters"])
                    setDates([...Object.values(data["Dates"]["Dates"]).sort((a,b)=>{return (new Date(a).getTime()-(new Date(b).getTime()))})])
                    setDatesOriginal([...Object.values(data['Dates']['Dates'])])
                    var temp = getOptionType(data["DropdownMasters"])
                    setOptionType(temp)
                    setColumns(getColumns(data["ExistingTable"], Object.values(data["Dates"]["Dates"]).sort((a,b)=> {return (new Date(a).getTime()-(new Date(b).getTime()))}),deleteCheckBox, showPrevious));
                    setRows(getRows(data["ExistingTable"], temp, Object.values(data["Dates"]["Dates"]).sort((a,b)=> {return (new Date(a).getTime()-(new Date(b).getTime()))}),deleteCheckBox,deleteCheckBoxColumns, showPrevious))
                    setMaxID(findMaxKey(data["ExistingTable"]))
                    setMaxIDInDB(findMaxKey(data["ExistingTable"]))
                })
                setDataSaved(true)

            }
        }
    }, [account, navigate, ActiveEnv, setDataSaved, retrieveURL,deleteCheckBox,deleteCheckBoxColumns,showPrevious, dataGrouped, getRows, getColumns, getOptionType,fetchData])



    const deleteSelectedRows = async () => {
        let changesTracker1 =changesTracker
        const rowstodelete = deleteTracker['rowstodelete']
                var rows_to_database =  []
                rowstodelete.forEach((value)=>{
                    if (dataGroupedOriginal[value]){
                        rows_to_database.push( {
                        "orderSKU":dataGroupedOriginal[value]['orderSKU'],
                        "warehouse_country":dataGroupedOriginal[value]["warehouse_country"]
                    })
                    }
                })
                if (rows_to_database.length>0){
                    let data={}
                data["rowstodeleteindatabase"]=rows_to_database
                data["deleterow"] = true
                data["comment"]=comment
                await deleteData(deleteURL,{method:"DELETE",body:data}).then((data) => {
                    if (data.Error) return;
                }).then(() => {
                    let dataGrouped1 = dataGrouped
                    let dataGroupedOriginal1 = dataGroupedOriginal 
                    rowstodelete.forEach((value147)=>{
                        delete dataGrouped1[value147]
                        let newData = value147>maxIDInDB?true:false
                        delete changesTracker1[`${ActiveEnv},${value147},${newData}`]
                        delete dataGroupedOriginal1[value147]
                     })
                    setDataGrouped(dataGrouped1)
                    setDataGroupedOriginal(dataGroupedOriginal1)
                    setMaxID(findMaxKey(dataGrouped))
                    setMaxIDInDB(findMaxKey(dataGroupedOriginal1))
                    setIsAlertBoxOpen(!isAlertBoxOpen);
                    setDeleteConfirmation(null)
                    setChangesTracker(changesTracker1)
                })
                } else {
                    const toastSettings = {
                        description: "Data Deleted Successfully",
                        status: 'success',
                        duration: 3000
        
                    }
                    if (toastIdRef.current) {
                        toast.update(toastIdRef.current, toastSettings)
                    } else {
                        toast(toastSettings)
                    }

                rowstodelete.forEach((value147)=> {
                    delete dataGrouped[value147]
                    delete changesTracker1[`${ActiveEnv},${value147},true`]
                    })
                setDataGrouped(dataGrouped)
                setMaxID(findMaxKey(dataGrouped))
                setChangesTracker(changesTracker1)
            }
            setDeleteTracker((prevData)=> {
                prevData['rowstodelete'] =[]
                return prevData
            })
            setColumns(getColumns(dataGrouped,Dates,deleteCheckBox, showPrevious));
            setRows(getRows(dataGrouped, optionType, Dates,deleteCheckBox,deleteCheckBoxColumns, showPrevious));
            setIsAlertBoxOpen(!isAlertBoxOpen);
            setDeleteConfirmation(null)
        }
         
    
    const DeleteRowsandColumns = async () =>{
        const deleteDates = deleteTracker["columnstodelete"]
        const dates123= Dates
        let changesTracker1 =  changesTracker
        deleteDates.forEach((deletevalue)=> {
            const indexToRemove = dates123.indexOf(deletevalue);
                            if (indexToRemove !== -1) {
                                dates123.splice(indexToRemove, 1);
                            } 
            let changesTrackerModfied = Object.entries(changesTracker1).map(([key,value])=>{
                value['id_changed'] = value["id_changed"].filter((value12)=>!(value12.includes(deletevalue)))
                value['newDatesAdded'] = value["newDatesAdded"].filter((value12)=>value12!==deletevalue)
                return [key,value]
            })
            changesTracker1 = Object.fromEntries(changesTrackerModfied)
        })
        var columnstodeleteindatabase = datesOriginal.filter(item => !dates123.includes(item));
        if (columnstodeleteindatabase.length>0) {
            let data={}
            data["columnstodeleteindatabase"] =columnstodeleteindatabase
            data["deletecolumn"] = true
            data["comment"]=comment
            await deleteData(deleteURL,{method:"DELETE",body:data}).then((data) => {
                if (data.Error) return;
            }).then(() => {
                setDatesOriginal([...dates123])
                setDeleteTracker((prevData)=>{
                    prevData['columnstodelete']=[]
                    return prevData
                })
                setDates(dates123)
            }).catch((error) => {
                const toastSettings = {
                    description: error.response.data.Error,
                    status: 'error',
                    isClosable: true,
                    duration: 2000
                }
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, toastSettings)
                } else {
                    toast(toastSettings)
                }
            }
            )
        }
        else {
            const toastSettings = {
                description: "Data Deleted Successfully",
                status: 'success',
                duration: 3000

            }
            if (toastIdRef.current) {
                toast.update(toastIdRef.current, toastSettings)
            } else {
                toast(toastSettings)
            }
        setDates(dates123)
        }
        setColumns(getColumns(dataGrouped,dates123,deleteCheckBox, showPrevious));
        setChangesTracker(changesTracker1)
        setRows(getRows(dataGrouped, optionType, dates123,deleteCheckBox,deleteCheckBoxColumns, showPrevious));
        setDeleteConfirmation(null)
        setIsAlertBoxOpen(!isAlertBoxOpen)
    }


    const uploadData = () => {
        setIsButtonDisabled(true);
        let data = [];
        let blankSpace = []

        Object.entries(changesTracker).forEach(([key,value1]) => {
            const newCustomer = key.split(',')[2]
            if (newCustomer==='false') {
            Object.entries(value1).filter(([key4,value4])=>(key4==='id'||key4==='warehouse_country'||key4==='orderSKU')).forEach(([key,value])=> {
                if (value===null||value==="")
                {
                    blankSpace.push([value1["id"],key])
                }
            })
        } else {
            Object.entries(value1).filter(([key4,value4])=>(key4==='id'||key4==='warehouse_country'||key4==='orderSKU')).forEach(([key,value])=> {
                if (value===null||value==="")
                {
                    blankSpace.push([value1["id"],key])
                }
            })
            if (value1['newDatesAdded'].length===0) {
                    blankSpace.push([value1["id"],"Dates"])
            }
        }
        })
        if (blankSpace.length > 0) {
        let ErrorMsg = ""
        blankSpace.forEach((value)=> {
            ErrorMsg = ErrorMsg + "Blank Space Found for ID "+value[0]+" for "+value[1]+". "
        })
        
        const toastSettings = {
            description: ErrorMsg,
            status: 'error',
            isClosable: true,
            containerStyle: {
                maxHeight: "300px"
            }    
        };

        if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings)
        } else {
            toast(toastSettings)
        }
        }
        else {
            Object.entries(changesTracker).forEach(([key, newValue]) => {
                const [Env, ID, newCustomer] = key.split(",");
                data.push({
                    updatedDict: newValue,
                    ID: ID,
                    newCustomer: newCustomer,
                    comment:comment
                });
            });
            updateData(updateURL, { method:"PUT",body:data}).then((data) => {
                if (data.Error) return;
            }).then(() => {
                // updateTotals(totals, pivotedData, setTotals, setPivotedData, changesTracker)
                setDataSaved(true)
                setMaxIDInDB(findMaxKey(dataGrouped))
                setDataGroupedOriginal(JSON.parse(JSON.stringify(dataGrouped)))
                setDatesOriginal([...Dates])
                setChangesTracker({})
                setDeleteConfirmation(null)
                setIsAlertBoxOpen(!isAlertBoxOpen)
            })
        }
    };


    if (error) return (<p>Follwing Error has occurred {error}</p>)
    if (loading || !ActiveEnv) return (
        <Container minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            < Box
                className="Loading-Data"
                maxW="md"
                mx="auto"
                p={6}
                borderRadius="xl"
                boxShadow="md"
                backgroundColor={"white"}
            >
                <CustomSpinner text="Loading Your Data.. Please Wait" />
            </Box >
        </Container>
    )

    // .filter(([filterKey,filterValue])=>(customer_name_filter==="All"|filterValue[CustomerRowMapping["Customer Name"]] ===customer_name_filter|filterValue[CustomerRowMapping["Customer Name"]] ==="")&&(filterValue[CustomerRowMapping["Channel"]] ===channel_filter|filterValue[CustomerRowMapping["Channel"]] ==="")&&(filterValue[CustomerRowMapping["SOP Category"]] ===sop_category_filter|filterValue[CustomerRowMapping["SOP Category"]] ===""))

    return (
        <Flex flex="1" flexDirection="column">
            <Container maxW="container.xxl" py={8}>
                <Stack spacing={8}>
                    <Tabs variant="enclosed" bg="white" boxShadow="md">
                        <TabList>
                            <Tab>{prettySheets.filter((value)=>value[0]===parseInt(id_sheet))[0][1]}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <HStack spacing={4}>
                                    <SaveButton isButtonDisabled={isButtonDisabled} uploadData={() => {setIsAlertBoxOpen(!isAlertBoxOpen);setDeleteConfirmation("Save")}} />
                                    
                                    <Button style={{ "marginTop": "16px", width: "130px" }} fontSize="md" fontWeight="bold" onClick={onOpen} colorScheme="blue" leftIcon={<BsCardList />}>Change Logs</Button>

                                    <Tooltip label={(!showPrevious) ?"Show Previous SKU Mappings":"Hide Previous SKU Mappings"} placement='top'>
                                    <Button style={{ "marginTop": "16px", width: "140px" }} fontSize="md" fontWeight="bold" onClick={handleShowPrevious} colorScheme="blue" leftIcon={(!showPrevious) ? <BiShow /> : <BiHide />}>{(!showPrevious) ? 'Show' : 'Hide' } Previous</Button>
                                    </Tooltip>

                                    <Input style={{"marginTop":"16px", width:"250px"}} type="Date" value={toAddDate?toAddDate:''}  onChange={((e)=>SetToAddDate(e.target.value))}></Input>
                                    
                                    <Button style={{"marginTop":"16px", width:"110px"}} fontSize="md" fontWeight="bold" isDisabled={!toAddDate||toAddDate===''} onClick={handleDateAddition} colorScheme="blue" leftIcon={<IoAddCircleOutline />}>Add Date</Button>
                                    
                                    {!deleteCheckBox && <Button style={{"marginTop":"16px", width:"130px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckbox} colorScheme="red" leftIcon={<MdOutlineTableRows />}>Delete Rows</Button>}
                                    
                                    {deleteCheckBox && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={() => {setIsAlertBoxOpen(!isAlertBoxOpen);setDeleteConfirmation("Rows")}} colorScheme="red" leftIcon={<MdOutlineTableRows />}>Delete Selected Rows</Button>}
                                    
                                    {deleteCheckBox && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckbox} colorScheme="yellow" leftIcon={<MdOutlineCancel />}>Cancel Delete Rows</Button>}
                                    
                                    {!deleteCheckBoxColumns && <Button style={{"marginTop":"16px", width:"130px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckboxcolumns} colorScheme="red" leftIcon={<BsCalendar2X />}>Delete Dates</Button>}
                                    
                                    {deleteCheckBoxColumns && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={() => {setIsAlertBoxOpen(!isAlertBoxOpen);setDeleteConfirmation("Dates")}} colorScheme="red" leftIcon={<BsCalendar2X />}>Delete Selected Dates</Button>}
                                    
                                    {deleteCheckBoxColumns && <Button style={{"marginTop":"16px", width:"190px"}} fontSize="md" fontWeight="bold" onClick={handleDeleteCheckboxcolumns} colorScheme="yellow" leftIcon={<MdOutlineCancel />}>Cancel Delete Dates</Button>}
                                
                                    {prettySheets && <Box position="absolute" right="2%"><RegionalLeaderTable name={prettySheets.filter((value)=>value[0]===parseInt(id_sheet))[0][1]} /></Box>}
                                </HStack>
                                <Box minHeight="10vh" paddingBottom={10}>
                                    <Box maxHeight="60vh" overflow="auto" textAlign="center" id='printableArea' className='react-grid'>
                                        {/* <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
                                        <div id="reactgrid-red">
                                            <ReactGrid rows={rows} columns={columns} stickyTopRows={deleteCheckBoxColumns ? 2 : 1} stickyLeftColumns={deleteCheckBox ? 2 : 1} disableVirtualScrolling={false} onColumnResized={handleColumnResize} onCellsChanged={handleChanges} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid>
                                        </div>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Stack>

                {
                    (isAlertBoxOpen) && (
                        <CustomAlertDialogBox
                            isAlertBoxOpen={isAlertBoxOpen}
                            setIsAlertBoxOpen={setIsAlertBoxOpen}
                            onChangeCommentHandler={setComment}
                            themeColor={deleteConfirmation==="Save"?'green':'red'}
                            headerMsg={deleteConfirmation==="Save"?'Please confirm to Save':'Delete Selected '+deleteConfirmation+'?'}
                            bodyMsg={"Are you sure? You can't undo this action afterwards."}
                            onOKHandler={deleteConfirmation==="Save"?uploadData:deleteConfirmation==="Rows"?deleteSelectedRows:deleteConfirmation==="Dates"?DeleteRowsandColumns:null}
                        />
                    )
                }
                {
                isOpen && <ChangeLog PageName={pathname.split("-")[0]} isOpen={isOpen} onClose={onClose} onOpen={onOpen}/>
                }
            </Container>
        </Flex>
    );
}

